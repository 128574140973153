import h from 'hyperscript';

document.querySelectorAll('.grid--showmore').forEach(($section) => {
  // Get references
  const $items = $section.querySelectorAll('.grid__col');
  const $itemCount = $items.length;

  if ($itemCount > 3) {
    const $button = h('button.button.button--secondary.button--decoration.grid__show-more-button', h('span.button__text', 'Alle anzeigen'));

    $button.addEventListener('click', () => {
      $section.classList.add('grid--open');
      // Hide Load-More button
      $button.style.display = 'none';
    }, false);

    $section.appendChild(h('.grid__show-more', $button));
  }
});
