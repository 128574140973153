import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Choices from 'choices.js';
import h from 'hyperscript';
import icon from 'components/_particles/icon/icon';

const mql = window.matchMedia('(min-width: 768px)');

document.querySelectorAll('.js-program-search').forEach(($programSearch) => {
  const $steps = Array.prototype.slice.call($programSearch.querySelectorAll('.program-search__step'));
  const $selects = Array.prototype.slice.call($programSearch.querySelectorAll('.select'));
  const $submit = $programSearch.querySelector('.program-search__submit');
  const disabledClass = 'program-search__step--disabled';

  const enableStep = ($step) => {
    const $select = $step.querySelector('.select');
    const $selectInput = $step.querySelector('select');

    $step.classList.remove(disabledClass);

    $select.setAttribute('tabindex', '0');
    $selectInput.disabled = false;
    $selectInput.removeAttribute('aria-disabled');
  };

  const disableStep = ($step) => {
    const $select = $step.querySelector('.select');
    const $selectInput = $step.querySelector('select');

    $step.classList.add(disabledClass);

    $select.setAttribute('tabindex', '-1');
    $selectInput.disabled = true;
    $selectInput.setAttribute('aria-disabled', 'true');
  };

  // Check integrity before continuing
  if ($steps.length > 1 && $selects.length > 1 && $submit) {
    // Initialize custom select
    $selects.forEach((e, index) => {
      const choices = new Choices(e, { // eslint-disable-line
        searchEnabled: false,
        shouldSort: false,
        classNames: {
          containerOuter: 'choices select',
        },
      });

      // Add close button
      const $close = h(
        'button.choices__close',
        {
          type: 'button',
          title: 'Schließen',
        },
        icon({
          icon: 'close',
        }),
      );

      $close.addEventListener('click', () => choices.hideDropdown());

      const $panel = $steps[index].querySelector('.choices__list--dropdown');
      if ($panel) $panel.insertBefore($close, $panel.firstChild);

      e.addEventListener('showDropdown', (event) => {
        // Disable Body scrolling
        disableBodyScroll(event.target);

        if (mql.matches) {
          // Show backdrop
          document.body.classList.add('body--backdrop');
        }
      }, false);

      e.addEventListener('hideDropdown', (event) => {
        // Enable Body scrolling
        enableBodyScroll(event.target);

        if (mql.matches) {
          // Hide backdrop
          document.body.classList.remove('body--backdrop');
        }
      }, false);
    });

    // Set elements initially disabled
    $steps.forEach(($step) => {
      disableStep($step);
    });

    $submit.disabled = true;

    // Set first step active
    enableStep($steps[0]);

    $steps.forEach(($step) => {
      const $nextStep = $step.nextElementSibling;

      $step.querySelector('.select').addEventListener('change', () => {
        // Activate next step
        if ($nextStep && $nextStep.classList.contains(disabledClass)) {
          enableStep($nextStep);
        // Activate submit
        } else if (!$nextStep && $submit.disabled) {
          $submit.disabled = false;
        }
      });
    });
  }
});
