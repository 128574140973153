/* eslint-disable */

(function () {
  const AvhQuiz = {
    addClass(element, className) {
      if (!element.classList.contains(className)) {
        element.classList.add(className);
      }
    },
    removeClass(element, className) {
      if (element.classList.contains(className)) {
        element.classList.remove(className);
      }
    },
    NextButton: {
      hoverListener(event) {
        const parent = event.target.parentNode;
        const shadowList = parent.getElementsByClassName('quiz__nextbuttonshadow');
        if (shadowList.length < 1) {
          return;
        }
        AvhQuiz.addClass(shadowList[0], 'quiz__nextbuttonshadow--hovered');
      },
      mouseOutListener(event) {
        const parent = event.target.parentNode;
        const shadowList = parent.getElementsByClassName('quiz__nextbuttonshadow');
        if (shadowList.length < 1) {
          return;
        }
        AvhQuiz.removeClass(shadowList[0], 'quiz__nextbuttonshadow--hovered');
      },
    },
    Pages: {
      findPage(pages, targetPageId) {
        for (let i = 0; i < pages.length; i += 1) {
          if (pages[i].id === targetPageId) {
            return pages[i];
          }
        }
        return null;
      },
      revealPage(quiz, targetPageId) {
        const pagesInQuiz = quiz.getElementsByClassName('quiz__page');
        const showTransparentAnswerPage = new RegExp('_a(True|False)$').test(targetPageId);
        if (showTransparentAnswerPage) {
          const answerPage = AvhQuiz.Pages.findPage(pagesInQuiz, targetPageId);
          if (answerPage === null) {
            // console.warn(`Answer page ${targetPageId} not found.`);
            return;
          }
          AvhQuiz.addClass(answerPage, 'quiz__page--visibletransparent');
          AvhQuiz.addClass(answerPage, 'quiz__page--visible');
          return;
        }
        for (let i = 0; i < pagesInQuiz.length; i += 1) {
          if (pagesInQuiz[i].id !== targetPageId) {
            // hide other pages
            AvhQuiz.removeClass(pagesInQuiz[i], 'quiz__page--visible');
            AvhQuiz.removeClass(pagesInQuiz[i], 'quiz__page--visibletransparent');
          } else {
            // show target page
            AvhQuiz.addClass(pagesInQuiz[i], 'quiz__page--visible');
          }
        }
      },
      start(event) {
        const { quizId } = event.target.dataset;
        if (!quizId) {
          // console.warn('Missing quiz ID.');
          return;
        }
        const quiz = document.getElementById(`quiz${quizId}`);
        if (!quiz) {
          // console.warn(`Quiz ${quizId} not found.`);
          return;
        }
        const pages = quiz.getElementsByClassName('quiz__page');
        if (pages.length < 2) {
          // console.warn(`Quiz ${quizId} has no question page.`);
          return;
        }
        AvhQuiz.Pages.revealPage(quiz, pages[1].id);
      },
      open(event) {
        const { quizId } = event.target.dataset;
        const targetPageId = event.target.dataset.quizBtnTargetPage;
        if (!quizId || !targetPageId) {
          // console.warn(
          //   'Missing quiz ID or target page ID.',
          //   quizId,
          //   targetPageId
          // );
          return;
        }
        const quiz = document.getElementById(`quiz${quizId}`);
        if (!quiz) {
          // console.warn(`Quiz ${quizId} not found.`);
          return;
        }
        AvhQuiz.Pages.revealPage(quiz, targetPageId);
      },
    },
  };

  document.addEventListener('DOMContentLoaded', () => {
    const quizButtons = document.getElementsByClassName('quiz__button');
    for (let i = 0; i < quizButtons.length; i += 1) {
      if (quizButtons[i].classList.contains('quiz__button--start')) {
        quizButtons[i].addEventListener(
          'click',
          AvhQuiz.Pages.start,
        );
      } else {
        quizButtons[i].addEventListener(
          'click',
          AvhQuiz.Pages.open,
        );
      }
    }

    const nextButtons = document.getElementsByClassName('quiz__button--next');

    for (let i = 0; i < nextButtons.length; i += 1) {
      nextButtons[i].addEventListener(
        'mouseover',
        AvhQuiz.NextButton.hoverListener,
      );
      nextButtons[i].addEventListener(
        'mouseout',
        AvhQuiz.NextButton.mouseOutListener,
      );
    }
  });
}());

/* eslint-enable */
