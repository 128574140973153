import throttle from 'javascripts/utils/throttle';
import h from 'hyperscript';
import button from '../../atoms/button/button';

const changeTop = 200;
const $footer = document.getElementById('footer');

// Construct the to Top element
const $contentToTop = h('.body__to-top');
const $toTop = h('.to-top');
const $toTopButton = button({
  link: '#top',
  icon: 'hook-up',
  hiddenText: true,
  text: 'Zurück nach oben',
});

$toTop.appendChild($toTopButton);
$contentToTop.appendChild($toTop);
document.getElementById('top').appendChild($contentToTop);

const toTop = () => {
  const offset = ($el) => {
    const rect = $el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  };

  const scrollY = window.scrollY || window.pageYOffset;
  const footerOffset = offset($footer);
  const changeBottom = footerOffset.top - window.innerHeight - 100;

  if (scrollY > changeTop && scrollY < changeBottom) {
    $toTop.classList.add('to-top--visible');
  } else if (scrollY < changeTop || scrollY > changeBottom) {
    $toTop.classList.remove('to-top--visible');
  }
};

if (!document.documentElement.classList.contains('kb-preview')) {
  document.addEventListener('scroll', throttle(toTop), { passive: true });
}
