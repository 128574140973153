import Slider from 'javascripts/globals/slider';
import ResizeObserver from 'resize-observer-polyfill';
import getTarget from 'javascripts/utils/get-target';
import { BREAKPOINTS } from 'javascripts/constants';

class FigureSlider extends Slider {
  constructor($el, config, resizeObserver) {
    super($el, config);
    this.$el = $el;
    this.$controls = this.$el.querySelector('.figure-slider__controls');
    this.$figure = this.$el.querySelector('.figure__media');
    FigureSlider.setControls(this.$controls, this.$figure);
    resizeObserver.observe(this.$el);

    this.$figure.addEventListener('lazyloaded', () => {
      FigureSlider.setControls(this.$controls, this.$figure);
    });

    this.$el.addEventListener('after.lory.init', () => {
      FigureSlider.setControls(this.$controls, this.$figure);
    });
  }

  // calcualte the figure media (image or video) and place the controls beneath (on desktop)
  static setControls($controls, $figure) {
    const mediaQuery = $figure.closest('.grid') ? BREAKPOINTS.l : BREAKPOINTS.m;
    if ($controls && $figure && window.matchMedia(`(min-width: ${mediaQuery})`).matches) {
      const hasFigCaption = $figure.parentNode.querySelector('figcaption');

      const top = $figure.offsetHeight + 2 || $figure.clientHeight + 2;
      // $controls.setAttribute('style', `bottom: auto; top: ${top}px`);

      setTimeout(() => {
        $controls.setAttribute('style', `bottom: auto; top: ${top}px; display: block;`);
      }, 1000);

      if (!hasFigCaption && $figure.closest('.figure-slider')) {
        $figure.closest('.figure-slider').classList.add('figure-slider--nofigcaption');
      }
    } else {
      $controls.removeAttribute('style', null);
      $controls.setAttribute('style', 'display: block;');
    }
  }

  static onResize(sliders) {
    sliders.forEach(($slider) => {
      const $target = getTarget($slider.target, '.figure-slider');
      const $controls = $target.querySelector('.figure-slider__controls');
      const $figure = $target.querySelector('.figure__media');
      FigureSlider.setControls($controls, $figure);
    });
  }
}

const componentStore = [];

document.querySelectorAll('.figure-slider').forEach(($figureSlider) => {
  const observer = new ResizeObserver(FigureSlider.onResize);
  componentStore.push(new FigureSlider($figureSlider, {
    sliderFrame: 'figure-slider__frame',
    slidesContainer: 'figure-slider__items',
    sliderSlide: 'figure-slider__item',
    sliderControlsframe: 'figure-slider__controls',
    sliderDotsFrameClass: 'figure-slider__dots-frame',
    sliderDotsframe: 'figure-slider__inner',
    sliderControlsframemethod: 'prepend',
    sliderDotsframemethod: 'append',
  }, observer));
});
