document.querySelectorAll('.js-overlay-link').forEach(($link) => {
  const $target = $link.closest(`.${$link.getAttribute('data-overlay-target')}`);
  const $tooltip = $target.querySelector('.js-tooltip');

  let down;
  let up;

  if ($target) {
    $target.addEventListener('mouseover', () => {
      $link.classList.add('active-overlay');
      $target.style.cursor = 'pointer';
    });

    $target.addEventListener('mouseout', () => {
      $link.classList.remove('active-overlay');
      $target.removeAttribute('style');
    });

    // prevent click while user-selection
    $target.addEventListener('mousedown', () => {
      down = new Date();
    });

    $target.addEventListener('mouseup', (e) => {
      up = new Date();

      if (
        ($link !== e.target)
        && ($tooltip !== e.target)
        && ((up - down) < 1000)
        && (e.button !== 2)
      ) {
        if ($link.href) {
          $link.click();
        } else {
          const $nestedLink = $link.querySelector('a');
          if ($nestedLink && $nestedLink.href) {
            $nestedLink.click();
          }
        }
      }
    });

    $link.addEventListener('click', (e) => {
      // prevent double link clicks firing
      if (e.target !== $link) {
        e.preventDefault();
      }
    });
  }
});
