export default class NavigationFlyout {
  constructor($navigation) {
    this.$navigation = $navigation;
    this.$flyouts = this.$navigation.querySelectorAll('.navigation__flyout');
    this.$triggers = this.$navigation.querySelectorAll('[aria-haspopup="true"]');

    // Bind methods
    this.onKeydownBinded = this.onKeydown.bind(this);
    this.onTriggerClickBinded = this.onTriggerClick.bind(this);
  }

  init() {
    this.$navigation.classList.add('navigation--flyout');

    this.$flyouts.forEach(($flyout) => {
      $flyout.addEventListener('keydown', this.onKeydownBinded);
    });

    this.$triggers.forEach(($trigger) => {
      $trigger.addEventListener('click', this.onTriggerClickBinded);
    });
  }

  deinit() {
    this.$navigation.classList.remove('navigation--flyout');

    this.$flyouts.forEach(($flyout) => {
      $flyout.removeEventListener('keydown', this.onKeydownBinded);
    });

    this.$triggers.forEach(($trigger) => {
      $trigger.removeEventListener('click', this.onTriggerClickBinded);
    });
  }

  onKeydown(event) {
    if (event.key === 'Escape') {
      const $flyout = event.target.closest('.navigation__flyout');

      if ($flyout) {
        this.hideFlyout($flyout);
      }
    }
  }

  onTriggerClick(event) {
    const $trigger = event.currentTarget;
    const flyoutId = $trigger.getAttribute('aria-controls');
    const $flyout = this.$navigation.querySelector(`#${flyoutId}`);

    if ($flyout.getAttribute('aria-hidden') === 'true') {
      this.showFlyout($flyout, $trigger);
    } else {
      this.hideFlyout($flyout);
    }
  }

  showFlyout($flyout, $trigger) {
    $flyout.setAttribute('aria-hidden', 'false');
    $flyout.classList.add('navigation__flyout--open');
    $trigger.setAttribute('aria-expanded', 'true');
    $flyout.focus();
  }

  hideFlyout($flyout) {
    $flyout.setAttribute('aria-hidden', 'true');
    $flyout.classList.remove('navigation__flyout--open');

    const $trigger = this.$navigation.querySelector(`[aria-controls="${$flyout.id}"]`);

    if ($trigger) {
      $trigger.setAttribute('aria-expanded', 'false');
      $trigger.focus();
    }
  }
}
