import store from 'store/dist/store.modern';
import CustomEvent from 'custom-event';
import Cookies from 'js-cookie';
import Chart from 'components/molecules/chart/chart';
import { BREAKPOINTS } from 'javascripts/constants';

const COOKIESTATUS = {
  ACCEPT: 'accept',
  DECLINE: 'decline',
};

const COOKIES = {
  MARKETING: [
    {
      name: 'avh-cookie-marketing',
      expires: 365,
    },
    {
      name: 'avh-cookie-youtube',
      expires: 365,
    },
    {
      name: 'avh-cookie-maps',
      expires: 365,
    },
    {
      name: 'avh-cookie-gmaps',
      expires: 365,
    },
    {
      name: 'avh-cookie-twitter',
      expires: 365,
    },
  ],
  CONSENT: [
    {
      name: 'avh-cookie-consent',
      expires: 365,
    },
    {
      name: 'fe_typo_user',
      expires: 0,
    },
  ],
};

document.querySelectorAll('.js-cookie-alert').forEach(($alert) => {
  const $clone = document.importNode($alert.content, true);

  const onConsent = ($cookieAlert) => {
    const $consent = $cookieAlert.querySelector('[data-cookie="cookie-consent"]');
    const $marketing = $cookieAlert.querySelector('[data-cookie="cookie-marketing"]');
    // const $tracking = $cookieAlert.querySelector('[data-cookie="cookie-tracking"]');

    if ($marketing) {
      if ($marketing.checked) {
        Object.keys(COOKIES.MARKETING).forEach((key) => {
          Cookies.set(COOKIES.MARKETING[key].name, COOKIESTATUS.ACCEPT, { expires: COOKIES.MARKETING[key].expires, path: '/' });
        });
      } else {
        Object.keys(COOKIES.MARKETING).forEach((key) => {
          Cookies.set(COOKIES.MARKETING[key].name, COOKIESTATUS.DECLINE, { expires: COOKIES.MARKETING[key].expires, path: '/' });
        });
      }
    }

    if ($consent) {
      if ($consent.checked) {
        Object.keys(COOKIES.CONSENT).forEach((key) => {
          Cookies.set(COOKIES.CONSENT[key].name, COOKIESTATUS.ACCEPT, { expires: COOKIES.CONSENT[key].expires, path: '/' });
        });
      } else {
        Object.keys(COOKIES.CONSENT).forEach((key) => {
          Cookies.set(COOKIES.CONSENT[key].name, COOKIESTATUS.DECLINE, { expires: COOKIES.CONSENT[key].expires, path: '/' });
        });
      }
    }

    $cookieAlert.classList.add('cookie--hidden');
    store.set(COOKIES.CONSENT[0].name, true);
    const cookieAcceptEvent = new CustomEvent('cookieAccepted', {
      bubbles: true,
    });
    document.dispatchEvent(cookieAcceptEvent);

    /*
     * Reset cookie overlays for charts/maps after cookie layer button "ok" is clicked
     * This method loads charts/maps asyncronous into the DOM if marketing cookies are accepted
     */
    const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.m})`);
    const componentStore = [];
    document
      .querySelectorAll('.js-chart')
      .forEach(($chart) => {
        componentStore.push(new Chart($chart, {}, mql));
      });

    if ($marketing.checked) {
      window.location.reload();
    }
  };

  if (!store.get('avh-cookie-consent')) {
    document.body.appendChild($clone);
    const $cookieAlert = document.querySelector('.cookie');
    const $closeButton = $cookieAlert.querySelector('.cookie__button .button');

    if ($closeButton) {
      $closeButton.addEventListener('click', () => onConsent($cookieAlert));
    }
  }
});
