document.querySelectorAll('.js-cookie-collapse').forEach(($collapse) => {
  const $content = document.querySelector($collapse.getAttribute('aria-controls'));
  $collapse.addEventListener('click', (event) => {
    event.preventDefault();
    const isHidden = $collapse.getAttribute('aria-expanded') === 'false';

    $collapse.setAttribute('aria-expanded', isHidden ? 'true' : 'false');
    if ($content) {
      $content.setAttribute('aria-hidden', isHidden ? 'false' : 'true');
    }
  });
});

document.querySelectorAll('.cookie__dialog').forEach(($dialog) => {
  const $summary = $dialog.querySelector('.details__summary');
  if ($summary) {
    $summary.addEventListener('mouseover', () => {
      $dialog.setAttribute('open', true);
    });

    $summary.addEventListener('mouseout', () => {
      $dialog.removeAttribute('open');
    });
  }
});
