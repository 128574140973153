import { hover } from 'javascripts/utils/hide-show';
import NavigationOverlay from './navigation.overlay';
import NavigationFlyout from './navigation.flyout';

// Event registry
let eventRegistry = [];

document.querySelectorAll('.navigation').forEach(($navigation) => {
  const navigationOverlay = new NavigationOverlay($navigation);
  const navigationFlyout = new NavigationFlyout($navigation);

  $navigation.classList.add('js-navigation-initialized');

  // Init media query
  const mql = window.matchMedia('(min-width: 1200px)');

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;

    if (isDesktop) {
      const scroll = window.scrollY || window.pageYOffset;
      const $header = document.querySelector('.header');
      let change = 95;

      // Change point
      if ($header.classList.contains('header--login') && !$header.querySelector('.header__breadcrumb')) {
        change = 105;
      }

      if (scroll >= change) {
        navigationOverlay.init();
        navigationFlyout.deinit();
      } else if (scroll < change) {
        navigationOverlay.deinit();
        navigationFlyout.init();

        document.querySelectorAll('.navigation__item-link[aria-haspopup="true"]').forEach(($link) => {
          $link.addEventListener('mousedown', e => e.preventDefault());
          eventRegistry.push(hover($link, 200));
        });
      }
    } else {
      // Kill all event handlers on switch
      eventRegistry.forEach(e => e());
      eventRegistry = [];

      navigationOverlay.init();
      navigationFlyout.deinit();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});
