import Tooltip from '../../atoms/tooltip/tooltip';

document.querySelectorAll('.tooltip-hint').forEach(($share) => {
  const $trigger = $share.querySelector('.tooltip-hint__trigger');
  const $tooltip = $share.querySelector('.tooltip-hint__tooltip');

  return new Tooltip($trigger, $tooltip, {
    closeButtonClass: 'button.tooltip-hint__close',
    openOnHover: false,
    closeOnFocusOut: true,
    placement: 'top-start',
    orientations: ['top', 'bottom'],
    onClick: () => {
      $tooltip.focus();
    },
  });
});
