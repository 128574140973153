
/**
 * @author Thilo Ratnaweera <info@netbrothers.de>
 * @copyright © 2022 NetBrothers GmbH.
 *
 * Contains the logic for the AI puzzle picture. A puzzle picture is wrapped
 * into an image box which contains several pictures in different resolutions
 * and shows only one of them at a time. The main picture (initially shown) is a
 * low resolution picture which shows the whole scene. Several higher resolution
 * pictures (lazily loaded) show more detail of the scene. The set of images can
 * be viewed as several zoom layers.
 *
 * Zoom buttons allow for a switch between those layers. The image box works as
 * a window through which you can view and examine a portion of the bigger
 * picture. By dragging this window the shown image can moved around and be
 * observed in a step by step way to examine the whole scene.
 *
 * The current position of a higher resolution image is set through the `left`
 * and `top` CSS properties. If for example the highest resolution is four times
 * the lowest, then the range in which the image with the highest resolution can
 * be positioned in is -300% and 0% for either the `left` or the `top` property.
 * Moving the picture fully to the bottom right would mean that `left` and `top`
 * both have the value -300%.
 *
 * @todo reset scaling when returning to initial zoom level
 * @todo remove console output
 */
export default class AiPicturePuzzle {
  /**
   * Determines whether mouse or drag movements are momentarily captured or not.
   */
  captureMove = false;

  /**
   * The current `clientX` property of the mouse or touch event.
   */
  clientX = null;

  /**
   * The current `clientY` property of the mouse or touch event.
   */
  clientY = null;

  /**
   * Coeffecient for the scaling formula for small devices (phones).
   */
  coeff1 = 52.0780269665679;

  /**
   * Coeffecient for the scaling formula for small devices (phones).
   */
  coeff2 = -0.360163940548017;

  /**
   * Collection of detail buttons revealing more info about a topic.
   */
  detailButtons = [];

  /**
   * Horizontal offset percentage of the detail buttons.
   */
  detailButtonXOffset = -2;

  /**
   * Vertical offset percentage of the detail buttons.
   */
  detailButtonYOffset = -2;

  /**
   * On small devices (phones in essence) the image box is scaled with CSS
   * (`max-width: 650px; width: 100%`) to fit into the available space. If the
   * `100%` are less than the `max-width`, also the high resolution zoom layers
   * underneath the main image have to be scaled and translated correspondingly.
   *
   * @param x ratio between actual image width and available width times 12
   * @returns translation percent factor (negative value) for the given scaling factor
   */
  f(x) {
    return this.coeff1 * Math.exp(this.coeff2 * x);
  }

  /**
   * CSS `left` property for the currently displayed zoom layer.
   */
  imageLeftPos = 0;

  /**
   * CSS `top` property for the currently displayed zoom layer.
   */
  imageTopPos = 0;

  /**
   * Threshold to smoothen coarse mouse movements.
   */
  moveThresh = 15;

  /**
   * Wrapper element ("image box").
   */
  puzzleBox;

  /**
   * The maximum negative value for the `left` and `top` position of the
   * currently visible image. The image (when larger than the wrapper box, i.e.
   * with higher resolution and zoom level) may be positioned in a specific
   * range of values. This is different for each zoom level.
   *
   * Example:
   *
   * Fully zoomed in, the image is four times as big as the initial image, thus
   * providing a lot of detail. It is dissected into four viewing regions.
   * The initial image would fit four times inside the currently displayed
   * image. It can now be positioned between -300% and 0% for the x- and
   * the y-axis.
   *
   * ```css
   * img {
   *   left: -300%, top: -300%; // moved completely to the bottom right
   * }
   * ```
   */
  range = -0;

  /**
   * Ratio between actual image width and available width (0..1).
   */
  smallDeviceScale = 1;

  /**
   * Negative percent number representing the necessary translation of scaled-
   * down high resolution pictures (zoom layers) on small devices.
   */
  smallDeviceTranslation = 0;

  /**
   * The solution page.
   */
  solutionPage = null;

  /**
   * The image on the solution page.
   */
  solutionPageImage = null;

  /**
   * The headline on the solution page.
   */
  solutionPageHeadline = null;

  /**
   * The subheader on the solution page.
   */
  solutionPageSubheader = null;

  /**
   * The explanatory text on the solution page.
   */
  solutionPageText = null;

  /**
   * While `true` the user views a solution page.
   */
  solutionRevealed = false;

  /**
   * The currently visible layer.
   */
  visibleImage;

  /**
   * The currently active zoom level. Attention: (for mathematical reasons) the
   * lower the number, the higher the resolution. Zoom level 1 shows the most
   * detail and displays the layer with the highest resolution. We are starting
   * off with the lowest resolution.
   */
  zoomLevel = 4;

  /**
   * The lowest available zoom level.
   */
  zoomLevelFullPicture = 4;

  /**
   * Initializes the puzzle box for the given element. Any number of elements
   * may be initialized.
   * @see ./puzzle-image.pug
   * @param puzzleBox wrapper element
   */
  constructor(puzzleBox) {
    // initialize properties
    this.puzzleBox = puzzleBox;
    this.initSolutionPage();
    this.initStartButton();
    this.selectImage(this.zoomLevelFullPicture);
    this.visibleImage.addEventListener('load', () => {
      if (this.visibleImage.naturalWidth === 0) {
        // console.error('AiPicturePuzzle: initialisation error');
        return;
      }

      // scaling down for small devices
      if (this.visibleImage.naturalWidth > this.puzzleBox.clientWidth) {
        this.smallDeviceScale = this.puzzleBox.clientWidth / this.visibleImage.naturalWidth;
        this.smallDeviceTranslation = -100 * (this.f(this.smallDeviceScale * 12) / 12);
        const notDraggable = this.imageClassByZoomLevel(this.zoomLevelFullPicture);
        const draggableSelector = `.picture-puzzle__image:not(.${notDraggable})`;
        const draggableImages = this.puzzleBox.querySelectorAll(draggableSelector);
        if (draggableImages.length > 0) {
          draggableImages.forEach(image => this.applyStyle(image));
        }
      }

      this.updateRange();
    });

    // inizialize zoom buttons
    const zoomButtons = this.puzzleBox.getElementsByClassName('picture-puzzle__zoom-button');
    Array.from(zoomButtons).forEach((el) => {
      ['mouseup', 'touchend'].forEach((eventName) => {
        el.addEventListener(eventName, (event) => {
          event.preventDefault();
          if (el.classList.contains('picture-puzzle__zoom-button--zoomout')) {
            this.changeZoomLevel(this.zoomLevel + 1); // zoom out
          } else {
            this.changeZoomLevel(this.zoomLevel - 1); // zoom in
          }
        });
      });
    });

    // initialize detail buttons
    this.detailButtons = Array.from(
      this.puzzleBox.getElementsByClassName('picture-puzzle__detail-button'),
    );
    this.detailButtons.forEach((el) => {
      ['mouseup', 'touchend'].forEach((eventName) => {
        el.addEventListener(eventName, (event) => {
          event.preventDefault();
          this.revealSolution(el);
        });
      });
    });

    // capture drag movements when initiated on puzzle image
    ['mousedown', 'touchstart'].forEach((eventName) => {
      puzzleBox.addEventListener(eventName, (event) => {
        if (this.solutionRevealed) {
          return;
        }
        this.startCapture(event);
      });
    });

    // listen to stop of drag movement anywhere on the page
    ['mouseup', 'touchend'].forEach((eventName) => {
      document.addEventListener(eventName, () => {
        this.stopCapture();
      });
    });

    // logic while dragging
    ['mousemove', 'touchmove'].forEach((eventName) => {
      document.addEventListener(eventName, (event) => {
        if (
          // dragging something else, not the image
          !this.captureMove
          // full display, no dragging
          || this.zoomLevel === this.zoomLevelFullPicture
        ) {
          return;
        }
        const mappedXMove = this.mapXMovement(this.getXMovement(event));
        const mappedYMove = this.mapYMovement(this.getYMovement(event));
        this.setImageLeftPos(this.imageLeftPos + mappedXMove);
        this.setImageTopPos(this.imageTopPos + mappedYMove);
        this.repositionDetailButtons();
        this.applyStyle();
      });
    });
  }

  closeSolution() {
    this.solutionRevealed = false;
    if (this.solutionPage.classList.contains('picture-puzzle__solution-page--visible')) {
      this.solutionPage.classList.remove('picture-puzzle__solution-page--visible');
    }
    this.solutionPageImage.src = '';
    this.solutionPageHeadline.textContent = '';
    this.solutionPageSubheader.textContent = '';
    this.solutionPageText.innerHTML = '';
  }

  initSolutionPage() {
    // store pointers to related elements
    this.solutionPage = this.puzzleBox.querySelector('.picture-puzzle__solution-page');
    this.solutionPageImage = this.solutionPage.querySelector('.picture-puzzle__solution-page img');
    this.solutionPageHeadline = this.solutionPage.querySelector('.picture-puzzle__headline');
    this.solutionPageSubheader = this.solutionPage.querySelector('.picture-puzzle__subheader');
    this.solutionPageText = this.solutionPage.querySelector('.picture-puzzle__text');

    // close button functionality
    const closeBtn = this.solutionPage.querySelector('.picture-puzzle__closebutton');
    if (!closeBtn) {
      // console.warn('AiPicturePuzzle: solution page close button not found');
      return;
    }
    ['mouseup', 'touchend'].forEach((eventName) => {
      closeBtn.addEventListener(eventName, (event) => {
        event.preventDefault();
        this.closeSolution();
      });
    });

    // close button shadow hover effect
    const closeBtnShadow = this.solutionPage.querySelector('.picture-puzzle__closebuttonshadow');
    if (!closeBtnShadow) {
      // console.warn('AiPicturePuzzle: solution page close button shadow not found');
      return;
    }
    closeBtn.addEventListener('mouseover', () => {
      if (!closeBtnShadow.classList.contains('picture-puzzle__closebuttonshadow--hovered')) {
        closeBtnShadow.classList.add('picture-puzzle__closebuttonshadow--hovered');
      }
    });
    closeBtn.addEventListener('mouseout', () => {
      if (closeBtnShadow.classList.contains('picture-puzzle__closebuttonshadow--hovered')) {
        closeBtnShadow.classList.remove('picture-puzzle__closebuttonshadow--hovered');
      }
    });
  }

  initStartButton() {
    const startBtns = this.puzzleBox.getElementsByClassName('picture-puzzle__startbutton');
    if (startBtns.length > 0) {
      ['mouseup', 'touchend'].forEach((eventName) => {
        startBtns[0].addEventListener(eventName, (event) => {
          event.preventDefault();
          const invite = this.puzzleBox.querySelector('.picture-puzzle__invite');
          if (invite) {
            invite.remove();
            const zoomButtonsWrapper = this.puzzleBox.getElementsByClassName('picture-puzzle__zoom-buttons');
            if (
              zoomButtonsWrapper.length > 0
              && !zoomButtonsWrapper[0].classList.contains('visible')
            ) {
              zoomButtonsWrapper[0].classList.add('visible');
            }
            this.repositionDetailButtons();
            this.revealDetailsButtons();
          }
        });
      });
    }
  }

  repositionDetailButtons() {
    Array.from(this.detailButtons).forEach((detailButton) => {
      if (!detailButton.dataset) {
        // console.warn('AiPicturePuzzle: Detail button has no dataset.');
        return;
      }
      if (
        !Object.prototype.hasOwnProperty.call(detailButton.dataset, 'solutionXPos')
        || !Object.prototype.hasOwnProperty.call(detailButton.dataset, 'solutionYPos')
      ) {
        // console.warn('AiPicturePuzzle: Detail button has no coordinates.');
        return;
      }

      // @todo small device scaling
      const zoomFactor = this.zoomLevelFullPicture - this.zoomLevel + 1;
      const relativeXPos = Number.parseFloat(detailButton.dataset.solutionXPos);
      const relativeYPos = Number.parseFloat(detailButton.dataset.solutionYPos);
      const newLeft = this.imageLeftPos + (relativeXPos * zoomFactor) + this.detailButtonXOffset;
      const newTop = this.imageTopPos + (relativeYPos * zoomFactor) + this.detailButtonYOffset;
      let newStyleDetailBtn = `left: ${newLeft}%; `;
      newStyleDetailBtn += `top: ${newTop}%; `;
      this.setStyleAttribute(detailButton, newStyleDetailBtn);
    });
  }

  revealDetailsButtons() {
    Array.from(this.detailButtons).forEach((detailButton) => {
      if (!detailButton.classList.contains('picture-puzzle__detail-button--visible')) {
        detailButton.classList.add('picture-puzzle__detail-button--visible');
      }
    });
  }

  revealSolution(element) {
    this.solutionRevealed = true;
    if (!this.solutionPage) {
      // console.warn('AiPicturePuzzle: solution page not found.');
      return;
    }
    if (!element || !element.dataset) {
      // console.warn('AiPicturePuzzle: solution data not found.');
      return;
    }
    if (!this.solutionPage.classList.contains('picture-puzzle__solution-page--visible')) {
      this.solutionPage.classList.add('picture-puzzle__solution-page--visible');
    }
    if (this.solutionPageImage) {
      if (Object.prototype.hasOwnProperty.call(element.dataset, 'solutionImage')) {
        this.solutionPageImage.src = element.dataset.solutionImage;
      } else {
        this.solutionPageImage.src = '';
      }
    }
    if (this.solutionPageHeadline) {
      if (Object.prototype.hasOwnProperty.call(element.dataset, 'solutionTitleDe')) {
        this.solutionPageHeadline.textContent = element.dataset.solutionTitleDe;
      } else {
        this.solutionPageHeadline.textContent = '';
      }
    }
    if (this.solutionPageSubheader) {
      if (Object.prototype.hasOwnProperty.call(element.dataset, 'solutionSubheaderDe')) {
        this.solutionPageSubheader.textContent = element.dataset.solutionSubheaderDe;
      } else {
        this.solutionPageSubheader.textContent = '';
      }
    }
    if (this.solutionPageText) {
      if (Object.prototype.hasOwnProperty.call(element.dataset, 'solutionTextDe')) {
        this.solutionPageText.innerHTML = Buffer.from(element.dataset.solutionTextDe, 'base64').toString();
      } else {
        this.solutionPageText.innerHTML = '';
      }
    }
  }

  setImageLeftPos(imageLeftPos) {
    if (Number.isNaN(imageLeftPos)) {
      return;
    }
    this.imageLeftPos = this.narrow(imageLeftPos, this.range, 0);
  }

  setImageTopPos(imageTopPos) {
    if (Number.isNaN(imageTopPos)) {
      return;
    }
    this.imageTopPos = this.narrow(imageTopPos, this.range, 0);
  }

  changeZoomLevel(newZoomLevel) {
    if (newZoomLevel > this.zoomLevelFullPicture || newZoomLevel < 1) {
      // console.warn('AiPicturePuzzle: Invalid zoom level.');
      return;
    }

    let firstZoomIn = false;
    if (
      this.zoomLevel === this.zoomLevelFullPicture
      && newZoomLevel === (this.zoomLevelFullPicture - 1)
    ) {
      firstZoomIn = true;
    }

    this.selectImage(newZoomLevel);

    // set new zoom level and recalculate coordinates
    const oldRange = this.range;
    // console.debug('old range', oldRange);
    this.zoomLevel = newZoomLevel;
    this.updateRange();

    // adjust viewing position to new zoom level
    let newLeft = this.mapRange(this.imageLeftPos, oldRange, this.range);
    let newTop = this.mapRange(this.imageTopPos, oldRange, this.range);
    if (firstZoomIn) {
      // center view when zooming in from full picture
      newLeft = -50;
      newTop = -50;
    }
    this.setImageLeftPos(newLeft);
    this.setImageTopPos(newTop);
    this.repositionDetailButtons();
    this.applyStyle();
  }

  selectImage(zoomLevel) {
    // hide currently selected image
    const currentImgSelector = 'img.picture-puzzle__image.visible';
    const currentImage = this.puzzleBox.querySelector(currentImgSelector);

    // `if` because at startup there is no current image
    if (currentImage) {
      currentImage.classList.remove('visible');
    }

    // set and show newly selected image
    const imageClassByZoomLevel = this.imageClassByZoomLevel(zoomLevel);
    this.visibleImage = this.puzzleBox.querySelector(`img.${imageClassByZoomLevel}`);
    this.visibleImage.classList.add('visible');
  }

  imageClassByZoomLevel(zoomLevel) {
    return `picture-puzzle__image--zoom-level-${zoomLevel}`;
  }

  getXMovement(event) {
    const newX = this.getX(event);
    const oldX = this.clientX;
    this.clientX = newX;
    return this.normalizeMovement(newX, oldX);
  }

  getYMovement(event) {
    const newY = this.getY(event);
    const oldY = this.clientY;
    this.clientY = newY;
    return this.normalizeMovement(newY, oldY);
  }

  normalizeMovement(newXY, oldXY) {
    let diff;
    if (newXY > oldXY) {
      diff = newXY - oldXY;
    } else {
      diff = -1 * (oldXY - newXY);
    }
    if (Math.abs(diff) > this.moveThresh) {
      // console.debug('clipped', diff);
      diff = Math.sign(diff) * this.moveThresh;
      // console.debug('new val', diff);
    }
    return diff;
  }

  mapXMovement(xMove) {
    return this.mapMovement(xMove, this.visibleImage.naturalWidth);
  }

  mapYMovement(yMove) {
    return this.mapMovement(yMove, this.visibleImage.naturalHeight);
  }

  mapMovement(move, heightOrWidth) {
    return 2 * (move / heightOrWidth * Math.abs(this.range));
  }

  enableGrabbingCursor() {
    if (!this.puzzleBox.classList.contains('picture-puzzle--dragging')) {
      this.puzzleBox.classList.add('picture-puzzle--dragging');
    }
  }

  disableGrabbingCursor() {
    if (this.puzzleBox.classList.contains('picture-puzzle--dragging')) {
      this.puzzleBox.classList.remove('picture-puzzle--dragging');
    }
  }

  highlightImage() {
    this.puzzleBox.classList.add('highlightable--enabled');
    setTimeout(() => {
      this.puzzleBox.classList.remove('highlightable--enabled');
    }, 500);
  }

  /**
   * @param input percentage (number between 0 and 1)
   * @param sourceRangeBegin lower end of source range
   * @param targetRangeBegin lower end of target range
   * @returns
   */
  mapRange(
    input,
    sourceRangeBegin,
    targetRangeBegin,
  ) {
    if (input < sourceRangeBegin || input > 0) {
      throw new Error('mapRange: input out of bounds');
    }
    if (sourceRangeBegin > 0 || targetRangeBegin > 0) {
      throw new Error('mapRange: range out of bounds');
    }
    if (sourceRangeBegin === 0) {
      return sourceRangeBegin;
    }
    return input / sourceRangeBegin * targetRangeBegin;
  }

  narrow(input, min, max) {
    if (input < min) {
      this.highlightImage();
      return min;
    }
    if (input > max) {
      this.highlightImage();
      return max;
    }
    return input;
  }

  setStyleAttribute(element, style) {
    element.setAttribute('style', style);
  }

  applyStyle(element) {
    let newStyle = `left: ${this.imageLeftPos}%; `;
    newStyle += `top: ${this.imageTopPos}%; `;
    if (
      element !== undefined
      || !this.visibleImage.classList.contains(
        this.imageClassByZoomLevel(this.zoomLevelFullPicture),
      )
    ) {
      newStyle += 'transform: ';
      newStyle += `scale(${this.smallDeviceScale}) `;
      newStyle += `translate(${this.smallDeviceTranslation}%, ${this.smallDeviceTranslation}%);`;
    }
    // console.debug('applying', newStyle, element);
    if (element === undefined) {
      this.setStyleAttribute(this.visibleImage, newStyle);
      return;
    }
    this.setStyleAttribute(element, newStyle);
  }

  /**
   * The image may be positioned in a specific range of values. This is
   * different for each zoom level.
   *
   * Example:
   *
   * Fully zoomed in, the image is four times as big as the initial image, thus
   * providing a lot of detail. It is dissected into four viewing regions.
   * The initial image would fit four times inside the currently displayed
   * image. It can now be positioned between -300% and 0% for the x- and
   * the y-axis.
   *
   * ```css
   * img {
   *   left: -300%, top: -300%; // image moved completely to the bottom right
   * }
   * ```
   */
  updateRange() {
    // number of viewing regions (window count)
    const windowCount = this.zoomLevelFullPicture - this.zoomLevel + 1;
    this.range = (windowCount - 1) * -100; // e.g. 4 windows yields -300%
  }

  startCapture(event) {
    this.enableGrabbingCursor();
    if (event.preventDefault) event.preventDefault();
    if (this.clientX === null) {
      this.clientX = this.getX(event);
    }
    if (this.clientY === null) {
      this.clientY = this.getY(event);
    }
    this.captureMove = true;
  }

  stopCapture() {
    /**
     * Since this is triggered by a global event (`document.addEventListener...`),
     * it has to execute only if dragging is in progress.
     */
    if (!this.captureMove) {
      return;
    }
    this.disableGrabbingCursor();
    this.captureMove = false;
  }

  getX(event) {
    if (event instanceof MouseEvent) {
      return event.clientX;
    }
    if (event instanceof TouchEvent) {
      return event.touches[0].clientX;
    }
    return NaN;
  }

  getY(event) {
    if (event instanceof MouseEvent) {
      return event.clientY;
    }
    if (event instanceof TouchEvent) {
      return event.touches[0].clientY;
    }
    return NaN;
  }
}
