/**
 * @typedef {object} TableRankingClasses
 * @property {string} element
 * @property {string} initializedModifier
 * @property {string} navigationTab
 * @property {string} activeTabModifier
 * @property {string} tabContainer
 * @property {string} activeContainerModifier
 * @property {string} tooltip
 * @property {string} filter
 */

/**
 * @typedef {object} RankingTableClasses
 * @property {string} element
 * @property {string} initializedModifier
 * @property {string} columnHeader
 * @property {string} tooltipButton
 * @property {string} sortButton
 * @property {string} tableCell
 * @property {string} tableRow
 * @property {string} datasetShownModifier
 * @property {string} datasetHiddenModifier
 * @property {string} tooltipModifier
 */

/**
 * @typedef {object} TabElement
 * @property {HTMLElement} $trigger
 * @property {HTMLElement} $tabContainer
 */


export class RankingTable {
  /** @type {HTMLTableElement} */ $element;

  /** @type {RankingTableClasses} */ classes;

  /** @type {RankingTableClasses} */
  static DefaultClasses = {
    element: 'table-ranking__table',
    initializedModifier: 'table-ranking__table--initialized',
    columnHeader: 'table-ranking__column-head',
    tooltipButton: 'table-ranking__tooltip-button',
    sortButton: 'table-ranking__sort-button',
    tableRow: 'table-ranking__table-row',
    tableCell: 'table-ranking__table-cell',
    datasetShownModifier: 'table-ranking__table--shown',
    datasetHiddenModifier: 'table-ranking__table--hidden',
    tooltipModifier: 'table-ranking__column-head--tooltip',
  };

  constructor($element, classes = {}) {
    this.$element = $element;
    this.classes = {
      ...RankingTable.DefaultClasses,
      ...classes,
    };

    /**
     * Initialize the component and append the init modifier afterwards
     */
    this.Init().then(() => {
      this.$element.classList.add(this.classes.initializedModifier);
      // console.log(this);
    });
  }

  /**
   * Initializes the component
   */
  async Init() {
    const { $element, classes } = this;

    $element // Initialize each table column to sort
      .querySelectorAll(`.${classes.columnHeader}`)
      .forEach((/** @type {HTMLTableCellElement} */ $columnHeader) => {
        const $sortButton = $columnHeader.querySelector(`.${classes.sortButton}`);
        const $tooltipButton = $columnHeader.querySelector(`.${classes.tooltipButton}`);
        const columIndex = $columnHeader.cellIndex;

        if ($tooltipButton) {
          $columnHeader.classList.add(classes.tooltipModifier);
        }

        if ($sortButton) {
          $sortButton.addEventListener('click', () => {
            const $columnHead = $sortButton.closest('th');
            this.updateTableSort($columnHead, $sortButton);

            /**
             * Map the sort direction to a number. The sign indicates the sort direction
             */
            const sortDirection = {
              asc: 1,
              desc: -1,
              none: 0,
            }[$columnHead.dataset.sort];

            this.sortTable(columIndex, (cellA, cellB) => {
              const valueA = parseFloat(cellA.dataset.sortValue);
              const valueB = parseFloat(cellB.dataset.sortValue);
              return sortDirection * (valueA - valueB);
            });
          });
        }
      });
  }

  /**
   * Updates the table elements after sorting
   * @param {HTMLTableCellElement} $tableHeader
   * The table header element which triggered the sortUpdate
   * @param {HTMLButtonElement} $sortButton The button to which the icon is updated
   */
  updateTableSort($tableHeader, $sortButton) {
    /**
     * Simple state map to the next sorting direction
     */
    $tableHeader.dataset.sort = { // eslint-disable-line
      asc: 'desc',
      desc: 'none',
      none: 'asc',
    }[$tableHeader.dataset.sort];

    /**
     * Maps the sorting direction to the indicating button icon
     */
    const sortIcon = {
      asc: 'arrow-up',
      desc: 'arrow-down',
    }[$tableHeader.dataset.sort];

    const $buttonIcon = sortIcon && $sortButton.querySelector('use');

    if ($buttonIcon) {
      $buttonIcon.setAttribute('xlink:href', `#icon-${sortIcon}`);
    }
  }

  /**
   * Sorts the table according to the given column and compareFunc
   * @param {number} columnIndex The 0-based index of the table column
   * @param {(cellA: HTMLTableCellElement, cellB: HTMLTableCellElement) => number} compareFunc
   * A callback which determines the values to be compared
   */
  sortTable(columnIndex, compareFunc) {
    const { $element, classes } = this;
    const $tbody = $element.querySelector('tbody');
    const cellsToCompare = Array.from(
      $element.querySelectorAll(
        `.${classes.tableCell}:nth-child(${columnIndex + 1})`,
      ),
    );
    cellsToCompare.sort(compareFunc);
    cellsToCompare
      .map($cell => $cell.parentElement) // Map the table cell to its row
      .forEach($row => $tbody.insertAdjacentElement('beforeend', $row)); // Insert each row at the end of the table
  }

  showTable() {
    const { $element, classes } = this;
    $element.classList.remove(classes.datasetHiddenModifier);
  }

  hideTable() {
    const { $element, classes } = this;
    $element.classList.add(classes.datasetHiddenModifier);
  }

  static InitializeDefaults() {
    const { element, initializedModifier } = RankingTable.DefaultClasses;
    const qryString = `.${element}:not(.${initializedModifier})`;

    document
      .querySelectorAll(qryString)
      .forEach($table => new RankingTable($table));
  }
}

export default class TableRanking {
  /** @type {TableRankingClasses} */
  static DefaultClasses = {
    element: 'js-table-ranking',
    initializedModifier: 'table-ranking--initialized',
    navigationTab: 'table-ranking__tab',
    activeTabModifier: 'table-ranking__tab--active',
    tabContainer: 'table-ranking__tab-container',
    activeContainerModifier: 'table-ranking__tab-container--open',
    filter: 'table-ranking__filter',
  }

  /** @type {HTMLElement} */ $element;

  /** @type {TableRankingClasses} */ classes;

  /**
   * A map for the tabs. The container's id serves as mapping key to the trigger.
   * @type {{[index:string]: TabElement}}
   */
  tabs = {};

  /** @type {HTMLSelectElement} */
  $filter;

  /**
   * @param {HTMLElement} $element
   * @param {TableRankingClasses} classes
   */
  constructor($element, classes) {
    this.$element = $element;
    this.classes = {
      ...TableRanking.DefaultClasses,
      ...classes,
    };

    this.Init().then(() => {
      this.$element.classList.add(this.classes.initializedModifier);
    });
  }

  async Init() {
    const { $element, classes } = this;

    $element
      .querySelectorAll(`.${this.classes.navigationTab}[aria-controls]`)
      .forEach((/** @type {HTMLElement} */ $tab) => {
        /** @type {{[index: string]: RankingTable}} */
        const $tables = {};
        const controlledId = $tab.getAttribute('aria-controls');
        const $tabContainer = document.querySelector(`#${controlledId}`);

        /** @type {HTMLSelectElement} */
        const $filter = $tabContainer && $tabContainer.querySelector(`.${classes.filter} select`);

        if ($tabContainer) {
          this.tabs[$tabContainer.id] = { $trigger: $tab, $tabContainer };
          $tab.addEventListener('click', () => {
            this.switchTab($tabContainer);
          });

          $tabContainer.querySelectorAll('table')
            .forEach(($table) => {
              const { id } = $table;
              $tables[id] = new RankingTable($table);
            });

          if ($filter && $tables) {
            $filter.addEventListener('change', () => {
              this.filterTables($tables, $filter);
            });

            this.filterTables($tables, $filter);
          }
        }
      });
  }

  /**
   * @param {{[index: string]: RankingTable}} $tables
   * @param {HTMLSelectElement} $filter
   */
  filterTables($tables, $filter) {
    /** @type {RankingTable} */
    const selectedTable = $tables[$filter.value];

    // eslint-disable-next-line
    for (const id in $tables) {
      if ($tables[id] === selectedTable) {
        $tables[id].showTable();
      } else {
        $tables[id].hideTable();
      }
    }
  }

  /**
   * Checks whether the given tab container is currently opened.
   * @param {HTMLElement} $tabContainer The container element to be checked.
   * @returns {boolean}
   */
  isTabOpened($tabContainer) {
    return $tabContainer.getAttribute('aria-expanded') === 'true';
  }

  /**
   * Opens a tab.
   * @param {HTMLElement} $tabContainer The actual tab content container.
   */
  openTab($tabContainer) {
    const { classes } = this;
    const { $trigger } = this.tabs[$tabContainer.id];

    $tabContainer.classList.add(classes.activeContainerModifier);
    $tabContainer.setAttribute('aria-expanded', 'true');

    if ($trigger) {
      $trigger.classList.add(classes.activeTabModifier);
    }
  }

  /**
   * Closes a tab.
   * @param {HTMLElement} $tabContainer The container element to be closed.
   */
  closeTab($tabContainer) {
    const { classes } = this;
    const { $trigger } = this.tabs[$tabContainer.id];

    $tabContainer.classList.remove(classes.activeContainerModifier);
    $tabContainer.setAttribute('aria-expanded', 'false');
    if ($trigger) {
      $trigger.classList.remove(classes.activeTabModifier);
    }
  }


  /**
   * Toggles the tab section. An already opened tab is unchanged.
   * @param {HTMLElement} $tabContainer The tab button to be toggled
   */
  switchTab($tabContainer) {
    Object.values(this.tabs)
      .map(tab => tab.$tabContainer)
      .filter($container => $container !== $tabContainer && this.isTabOpened($container))
      .forEach($container => this.closeTab($container));

    this.openTab($tabContainer);
  }

  static InitializeDefaults() {
    const { element, initializedModifier } = TableRanking.DefaultClasses;
    const qryString = `.${element}:not(.${initializedModifier})`;

    document
      .querySelectorAll(qryString)
      .forEach($tableRanking => new TableRanking($tableRanking));
  }
}

TableRanking.InitializeDefaults();
RankingTable.InitializeDefaults();
