import ToggleButton from './header-toggle';
import HeaderSticky from './header-sticky';
import HeaderBanner from './header-banner';

// Search toggle button
document.querySelectorAll('.header__button-search-toggle').forEach($toggleButton => new ToggleButton($toggleButton));

// Navigation toggle button
document.querySelectorAll('.header__button-navigation-toggle').forEach($toggleButton => new ToggleButton($toggleButton));

// Sticky header
document.querySelectorAll('.header__main').forEach($panel => new HeaderSticky($panel));

// Zoom level handling to disable box-shadow, which is
// covering UI elements at a large levels
let lastZoomLevel = window.devicePixelRatio;

const onZoomChange = () => {
  const currentZoomLevel = window.devicePixelRatio;

  if (currentZoomLevel !== lastZoomLevel) {
    lastZoomLevel = currentZoomLevel;

    const headerInner = document.querySelector('.header__inner');

    if (currentZoomLevel > 1.25) {
      headerInner.style.boxShadow = 'none';
    } else {
      headerInner.removeAttribute('style');
    }
  }
};

window.addEventListener('load', onZoomChange);
window.addEventListener('resize', onZoomChange);

// Compensate for header__banner height
document.querySelectorAll('.header__banner').forEach($banner => new HeaderBanner($banner));
