import mustache from 'mustache';
import jump from 'jump.js';
import 'intersection-observer';
import { BREAKPOINTS } from 'javascripts/constants';

document.querySelectorAll('.js-jumplist').forEach(($jumplist) => {
  const $header = document.querySelector('.header');
  let clicked = false;
  let $clickedItem = null;

  const observeHeaderState = (mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.attributeName === 'class') {
        const panelOpen = $header.classList.contains('header--panel-open');
        const unpinned = $header.classList.contains('header__headroom--unpinned');
        const pinned = $header.classList.contains('header__headroom--pinned');

        if (unpinned) {
          const $domJumpList = document.querySelector('.jumplist');
          if ($domJumpList) {
            const headerHeight = 45;

            $domJumpList.style.top = `${headerHeight}px`;
          }
        }

        if (!panelOpen && pinned) {
          const $domJumpList = document.querySelector('.jumplist');
          if ($domJumpList) {
            const headerHeight = $header.offsetHeight + 20;

            $domJumpList.style.top = `${headerHeight}px`;
          }
        }
      }
    });
  };

  // Observe header state to set dynamic top position
  const mutationObserver = new MutationObserver(observeHeaderState);
  const mqXL = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

  const onMediaQueryChange = () => {
    if (mqXL.matches) {
      mutationObserver.observe($header, { attributes: true });
    } else {
      mutationObserver.disconnect();
    }
  };
  mqXL.addListener(onMediaQueryChange);
  onMediaQueryChange();

  const activeOnScroll = (entries) => {
    const $domJumpList = document.querySelector('.jumplist');
    let firstActive = null;

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const $activeHeadline = $domJumpList.querySelector(`a[href='#${entry.target.headlineId}']`);

        // add active class to the first found intersecting block
        if ($activeHeadline && !firstActive && !clicked) {
          document.querySelectorAll('.jumplist__link.active').forEach($el => $el.classList.remove('active'));
          $activeHeadline.classList.toggle('active');
          firstActive = $activeHeadline;
        }

        if (clicked && $activeHeadline === $clickedItem) {
          clicked = false;
        }
      }
    });
  };

  const observer = new IntersectionObserver(
    entries => activeOnScroll(entries),
    { root: null, threshold: 0, rootMargin: '0px 0px -50% 0px' },
  );

  // Search pattern
  const searchPattern = $jumplist.getAttribute('data-search-pattern') || 'h2.headline';

  // Find all headlines
  const headlines = [];
  document.querySelectorAll(searchPattern).forEach(($headline) => {
    if ($headline.getAttribute('id')) {
      const altTitle = $headline.getAttribute('data-alt-title');
      headlines.push({
        href: `#${$headline.getAttribute('id')}`,
        title: altTitle || $headline.lastChild.textContent,
      });
    }

    const $contentBlock = $headline;
    $contentBlock.headlineId = $headline.id;
    observer.observe($contentBlock);
  });

  if (headlines.length === 0) {
    return;
  }

  // Render jumplist
  // eslint-disable-next-line no-param-reassign
  $jumplist.outerHTML = mustache.render($jumplist.innerHTML, { headlines });


  document.addEventListener('click', ($e) => {
    if ($e.target.matches('.jumplist__link')) {
      clicked = true;
      $clickedItem = $e.target;

      // Calculate offset for jump (header height)
      let jumpOffset = -200;
      if ($header) {
        jumpOffset = $header.getBoundingClientRect().height * -1;
      }

      jump($e.target.hash, {
        duration: 0,
        offset: jumpOffset,
        easing: false,
        callback: () => {
          document.querySelectorAll('.jumplist__link.active').forEach($el => $el.classList.remove('active'));
          $e.target.classList.toggle('active');
        },
      });
    }
  });
});
