import AutoComplete from '@tarekraafat/autocomplete.js';

document.querySelectorAll('[data-autosuggest]').forEach(($autosuggest) => {
  $autosuggest.addEventListener('autoComplete', (e) => {
    const $input = e.target;

    if (e.detail.matches > 0) {
      $input.classList.add('input--autosuggest-active');
    } else {
      $input.classList.remove('input--autosuggest-active');
    }
  });

  $autosuggest.addEventListener('blur', (e) => {
    const $input = e.target;
    const $autocompleteList = e.target.nextElementSibling;

    $input.classList.remove('input--autosuggest-active');
    $autocompleteList.classList.add('autoComplete_list--hidden');
  });

  $autosuggest.addEventListener('focus', (e) => {
    const $input = e.target;
    const $autocompleteList = e.target.nextElementSibling;

    if ($autocompleteList.children.length) {
      $input.classList.add('input--autosuggest-active');
    }

    $autocompleteList.classList.remove('autoComplete_list--hidden');
  });

  document.onkeydown = (e) => {
    const event = e || window.event;
    if (event.keyCode === 27) {
      if (event.target.classList.contains('input--autosuggest-active')) {
        const $autocompleteList = event.target.nextElementSibling;
        event.target.blur();
        $autocompleteList.classList.add('autoComplete_list--hidden');
        event.stopPropagation();
      }
    }

    event.stopPropagation();
  };

  // The autoComplete.js Engine instance creator
  const autoCompletejs = new AutoComplete({ // eslint-disable-line
    data: {
      src: async () => {
        // Loading placeholder text
        $autosuggest.setAttribute('placeholder', 'Loading...');
        // Construct Query URL
        const query = `${$autosuggest.getAttribute('data-autosuggest-url')}${$autosuggest.value}`;
        // Fetch External Data Source
        const source = await fetch(query);
        const data = await source.json();
        // Post loading placeholder text
        $autosuggest.setAttribute('placeholder', $autosuggest.getAttribute('data-autosuggest-placeholder'));
        // Returns Fetched data
        return data;
      },
      key: ['title'],
      cache: false,
    },
    sort: (a, b) => {
      if (a.match < b.match) return -1;
      if (a.match > b.match) return 1;
      return 0;
    },
    placeHolder: $autosuggest.getAttribute('data-autosuggest-placeholder'),
    selector: `[id=${$autosuggest.getAttribute('id')}]`,
    threshold: 2,
    debounce: 300,
    searchEngine: 'strict',
    highlight: true,
    maxResults: 10,
    resultsList: {
      render: true,
      container: (source) => {
        source.setAttribute('id', `autocomplete-list-${$autosuggest.getAttribute('name')}`);
        source.setAttribute('class', 'autoComplete_list');
      },
      destination: $autosuggest,
      position: 'afterend',
      element: 'ul',
    },
    resultItem: {
      content: (data, source) => {
        // eslint-disable-next-line no-param-reassign
        source.innerHTML = data.match;
      },
      element: 'li',
    },
    onSelection: (feedback) => {
      // eslint-disable-next-line no-param-reassign
      $autosuggest.value = feedback.selection.value.title;
      $autosuggest.classList.remove('input--autosuggest-active');
    },
  });
});
