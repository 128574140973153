import { t } from 'javascripts/utils/withTranslation';
import { lory } from '@rsm/allfarblori';
import h from 'hyperscript';
import generateIcon from 'components/_particles/icon/icon';

export default class Slider {
  constructor($el, options) {
    this.$el = $el;

    // Classes have to be assigned to slider elements
    if (!options) {
      return;
    }

    this.options = {
      sliderControlsframemethod: 'append',
      rewind: true,
      slidesToScroll: 1,
      slidesFocusable: true,
      scrollToActiveSlide: false,
      ...options,
    };

    // Slides
    this.$slides = this.$el.querySelectorAll(`.${this.options.sliderSlide}`);

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.slidesTotal = this.$slides.length;

    if (this.options.slidesToScroll > 1) {
      this.slidesTotal = Math.ceil(this.$slides.length / this.options.slidesToScroll);
    }

    this.initSlider();
  }

  initClasses() {
    // Frame
    this.$sliderFrame = this.$el.querySelector(`.${this.options.sliderFrame}`);
    // Container: Collection of slides
    this.$slidesContainer = this.$el.querySelector(`.${this.options.slidesContainer}`);
    // Controls-Frame: Where to dock the controls
    this.$controlsFrame = this.$el.querySelector(`.${this.options.sliderControlsframe}`);
    this.controlsFrameMethod = this.options.sliderControlsframemethod;
    // Dots-Frame: Where to dock the dots
    this.dotsFrameClass = this.options.sliderDotsFrameClass;
    this.$dotsFrame = this.$el.querySelector(`.${this.options.sliderDotsframe}`);
    this.dotsFrameMethod = this.options.sliderDotsframemethod;

    this.$sliderFrame.classList.add('slider__frame');
    this.$slidesContainer.classList.add('slider__container');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.setAttribute('tabindex', '0');
        }
        $item.classList.add('slider__slide');
      });
    }
  }

  initSlider() {
    this.initClasses();

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.initControls();
      this.initDots();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('slider--initialized');
      this.autoplayStart();
    });

    this.$el.addEventListener('after.lory.slide', () => {
      this.updateControls();
      this.updateDots();
    });


    if (this.options.autoplay && this.options.autoplay === true) {
      this.initAutoplay();
    }

    this.lory = lory(this.$el, {
      slidesToScroll: this.options.slidesToScroll,
      rewind: this.options.rewind,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: this.options.sliderFrame,
      classNameSlideContainer: this.options.slidesContainer,
      classNamePrevCtrl: 'slider__control--prev',
      classNameNextCtrl: 'slider__control--next',
    });

    if (this.$slidesContainer) {
      this.$slidesContainer.addEventListener('focusin', this.focusIn);
    }
    window.addEventListener('resize', this.onResize);
    this.lory.reset();
  }

  initAutoplay() {
    this.renderAutoplayButton();
    // hier müssen die funktionen angesteuert werden
  }

  renderAutoplayButton = () => {
    const $autoPlayButton = h(
      'button.slider__control.slider__control--autoplay',
      generateIcon({
        icon: 'play-fill',
        title: t('Autoplay an'),
      }), {
        type: 'button',
        onclick: this.toggleAutoPlayClick,
      },
    );

    this.$autoplayContainer = h('.slider__autoplay', $autoPlayButton);
    document.querySelector('.stage__controls').insertBefore(this.$autoplayContainer, null);
  };

  toggleAutoPlayClick = () => {
    if (this.autoplayPlays) {
      this.autoplayStop();
    } else {
      this.autoplayStart();
    }
  }


  autoplayStop = () => {
    if (this.$autoplayContainer) {
      while (this.$autoplayContainer.firstChild) {
        this.$autoplayContainer.removeChild(this.$autoplayContainer.firstChild);
      }

      const $playIcon = generateIcon({
        icon: 'play-fill',
        title: t('Autoplay an'),
      });


      const $autoPlayButtonPlay = h(
        'button.slider__control.slider__autoplay-button',
        {
          type: 'button',
          onclick: this.toggleAutoPlayClick,
        }, $playIcon,
      );

      this.$autoplayContainer.appendChild($autoPlayButtonPlay);
    }
    clearInterval(this.autoplayInvervall);
    this.autoplayPlays = false;
  };

  autoplayStart = () => {
    if (this.$autoplayContainer) {
      while (this.$autoplayContainer.firstChild) {
        this.$autoplayContainer.removeChild(this.$autoplayContainer.firstChild);
      }

      const $pauseIcon = generateIcon({
        icon: 'pause',
        title: t('Autoplay aus'),
      });

      const $autoPlayButtonPause = h(
        'button.slider__control.slider__autoplay-button',
        {
          type: 'button',
          onclick: this.toggleAutoPlayClick,
        },
        $pauseIcon,
      );

      this.autoplayInvervall = setInterval(() => {
        this.lory.next();
      }, this.options.autoPlayTime);
      this.$autoplayContainer.appendChild($autoPlayButtonPause);
      this.autoplayPlays = true;
    }
  }

  initDots() {
    const dotsLength = this.$slides.length;

    const $dotsWrapper = h(`.${this.dotsFrameClass}`);

    const $dots = h('ul.slider__dots');
    for (let i = 0; i < dotsLength; i += 1) {
      if (this.$slides[i]) {
        let dotClass = '.slider__dot';

        if (i === 0) {
          dotClass = `${dotClass}.slider__dot--active`;

          this.$slides[i].setAttribute('aria-hidden', 'false');
        } else {
          this.$slides[i].setAttribute('aria-hidden', 'true');
        }

        const $dot = h(
          'li.slider__dots-item', h(
            `button${dotClass}`, { type: 'button' }, h(
              'span.slider__dot-label', `Slide ${i}`,
            ),
          ),
        );

        $dot.addEventListener('click', (e) => {
          this.$el.querySelectorAll('.slider__dot').forEach(($el) => {
            $el.classList.remove('slider__dot--active');
          });

          e.target.closest('.slider__dot').classList.add('slider__dot--active');

          this.lory.slideTo(i);
        });

        $dots.appendChild($dot);
      }
    }

    $dotsWrapper.appendChild($dots);

    // Method defaults to append
    if (this.dotsFrameMethod) {
      this.$dotsFrame[this.dotsFrameMethod]($dotsWrapper);
    }
  }

  initControls() {
    if (this.$controls) {
      return;
    }

    const $controlPrev = h(
      'button.slider__control.slider__control--prev',
      generateIcon({
        icon: this.options.controlIconLeft || 'arrow-left',
        title: t('Vorherige Inhalte'),
      }),
    );

    const $controlCount = h(
      'span.slider__count',
      h('span.slider__current', '01'),
      h('span', '/'),
      h('span.slider__total', this.slidesTotal.toString().padStart(2, '0')),
    );

    const $controlNext = h(
      'button.slider__control.slider__control--next',
      generateIcon({
        icon: this.options.controlIconRight || 'arrow-right',
        title: t('Nächste Inhalte'),
      }),
    );

    this.$controls = h(
      '.slider__controls',
      $controlPrev,
      $controlCount,
      $controlNext,
    );

    // Method defaults to append
    if (this.controlsFrameMethod) {
      this.$controlsFrame[this.controlsFrameMethod](this.$controls);
    }
  }

  updateControls() {
    const currentIndex = this.lory.returnIndex() + 1;
    const shownIndex = Math.ceil(currentIndex / this.options.slidesToScroll);
    if (this.$el.querySelector('.slider__current')) {
      this.$el.querySelector('.slider__current').innerHTML = shownIndex.toString().padStart(2, '0');
    }
  }

  updateDots() {
    const currentIndex = this.lory.returnIndex();
    this.$el.querySelectorAll('.slider__dot').forEach(($el, i) => {
      $el.classList.remove('slider__dot--active');

      if (i === currentIndex) {
        $el.classList.add('slider__dot--active');
      }
    });

    this.$slides.forEach(($slide, i) => {
      if (i === currentIndex) {
        $slide.setAttribute('aria-hidden', 'false');
      } else {
        $slide.setAttribute('aria-hidden', 'true');
      }
    });
  }

  onResize = () => {
    this.updateControls();
  }

  deconstructor() {
    const controls = this.$el.querySelectorAll('.slider__controls');
    controls.forEach(($el) => {
      $el.parentNode.removeChild($el);
    });
    this.$el.classList.remove('slider--initialized');
    this.$sliderFrame.classList.remove('slider__frame');
    this.$slidesContainer.classList.remove('slider__container');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.removeAttribute('tabindex', null);
        }
        $item.classList.remove('slider__slide');
      });
    }

    if (this.lory) {
      this.lory.destroy();
    }
  }

  focusIn = (event) => {
    if (event.target) {
      const className = this.$slides[0].classList[0];
      this.$sliderFrame.scrollLeft = 0;
      const $slide = event.target.closest(`.${className}`);
      const index = Array.prototype.indexOf.call(this.$slides, $slide);
      this.lory.slideTo(index);

      if (this.options.scrollToActiveSlide) {
        const scrollOffset = -150;
        const scroll = $slide.getBoundingClientRect().top + window.pageYOffset + scrollOffset;
        window.scrollTo({ top: scroll, behavior: 'smooth' });
      }
    }
  }
}
