import { BREAKPOINTS } from 'javascripts/constants';
import plyr from 'plyr';
import plyrConfig from 'javascripts/utils/plyr-config';
import h from 'hyperscript';
import Figure from '../figure/figure';

// external Video
const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.m})`);

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];

  document
    .querySelectorAll('.js-figure-teaser')
    .forEach(($figure) => {
      componentStore.push(new Figure($figure, {
        wrapperClass: 'figure-teaser__media',
        playLinkClass: 'figure-teaser__play',
      }, mql));
    });
}

mql.addListener(onMediaQueryChange);
onMediaQueryChange();

// internal Video
const players = plyr.setup(document.querySelectorAll('.figure-teaser__video'), plyrConfig);

if (players) {
  players.forEach(($player) => {
    // hide headline on play
    $player.on('playing', (event) => {
      const $frame = event.target;
      if ($frame) {
        $frame.querySelector('.figure-teaser__info').style.display = 'none';
      }
    });
    // put play button and headline into the same container
    $player.on('ready', (event) => {
      const $frame = event.target;
      if ($frame) {
        const $wrapper = $frame.closest('.figure-teaser__video');
        if ($wrapper) {
          const title = $wrapper.getAttribute('data-title');
          const $headline = h('h3.headline.headline--3.f-w-semibold.headline--wide', title);
          const $button = $frame.querySelector('button.plyr__control--overlaid');
          $button.classList.add('figure-teaser__play-internal');
          const $captionBox = h('.figure-teaser__info', $button, $headline);
          $frame.appendChild($captionBox);
        }
      }
    });
  });
}
