document.querySelectorAll('.sort--dropdown').forEach(($dropdown) => {
  const $toggle = $dropdown.querySelector('.sort__button');

  const onOutsideClick = (event) => {
    if (!$dropdown.contains(event.target)) {
      document.removeEventListener('click', onOutsideClick);
      $toggle.setAttribute('aria-expanded', 'false');
      document.getElementById($toggle.getAttribute('aria-controls')).classList.remove('sort__list--visible');
    }
  };

  const toggleDropdown = (event) => {
    const $target = event.currentTarget;
    event.stopPropagation();

    if ($target) {
      const dropdownContentVisible = $toggle.getAttribute('aria-expanded') === 'true';
      const $content = document.getElementById($toggle.getAttribute('aria-controls'));

      // Switch aria state
      $toggle.setAttribute('aria-expanded', (!dropdownContentVisible).toString());

      // Switch content visiblity
      if (dropdownContentVisible) {
        $content.classList.remove('sort__list--visible');
      } else {
        $content.classList.add('sort__list--visible');
        document.addEventListener('click', onOutsideClick);
        document.addEventListener('focusin', onOutsideClick);
      }
    }
  };

  $dropdown.addEventListener('click', toggleDropdown);
  if ($toggle) {
    $toggle.addEventListener('click', toggleDropdown);
  }
});
