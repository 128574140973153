export default class HeaderBanner {
  constructor($headerBanner) {
    this.$headerBanner = $headerBanner;
    this.$headerBannerCloseButton = this.$headerBanner.querySelector('.banner__close-button');
    this.$page = document.querySelector('.page');

    // Get computed styles for the page
    this.computedPageStyles = window.getComputedStyle(this.$page);
    this.pagePaddingTop = parseInt(this.computedPageStyles.paddingTop, 10);

    this.bindedCloseClick = this.closeHeaderBanner.bind(this);
    this.$headerBannerCloseButton.addEventListener('click', this.bindedCloseClick);

    this.setPagePadding();
  }

  closeHeaderBanner() {
    this.setPagePadding();
  }

  setPagePadding() {
    // Get computed styles for the header__banner
    const computedHeaderBannerStyles = window.getComputedStyle(this.$headerBanner);
    const headerBannerHeight = parseInt(computedHeaderBannerStyles.height, 10);
    const newPagePaddingTop = this.pagePaddingTop + headerBannerHeight;

    this.$page.style.paddingTop = `${newPagePaddingTop}px`;
  }
}
