import focusTrap from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class ToggleButton {
  constructor($toggleButton) {
    this.$toggleButton = $toggleButton;
    this.$panel = document.getElementById(this.$toggleButton.getAttribute('aria-controls'));
    this.$closeButton = this.$panel.querySelector('.header__panel-close');
    this.$header = document.querySelector('.header');

    // Binded events
    this.bindedClick = this.toggle.bind(this);
    this.bindedPanelClick = this.panelClick.bind(this);
    this.bindedCloseClick = this.closePanel.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    this.$toggleButton.addEventListener('click', this.bindedClick);
    this.$closeButton.addEventListener('click', this.bindedCloseClick);

    // Init focus trap
    this.focusTrap = focusTrap(this.$panel, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
    });
  }

  toggle(event) {
    event.preventDefault();

    // Get current state of panel
    const isClosed = this.$toggleButton.getAttribute('aria-expanded') !== 'true';

    // Switch state
    if (isClosed) {
      this.openPanel();
    } else {
      this.closePanel();
    }
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      if (!event.target.classList.contains('input--autosuggest-active')) {
        event.preventDefault();
        this.closePanel();
      }
    }
  }

  panelClick(event) {
    if (event.target.closest('.search__inner') || event.target.closest('.navigation__inner')) return;

    this.closePanel();
  }

  closePanel() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'false');

    // Close Search Panel
    this.$panel.classList.remove('header__panel--open');
    this.$header.classList.remove('header--panel-open');

    // Remove keydown from body
    document.body.removeEventListener('keydown', this.onKeydownBinded);
    this.$panel.removeEventListener('click', this.bindedPanelClick);
    this.$closeButton.removeEventListener('click', this.bindedCloseClick);

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
  }

  openPanel() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'true');

    // Show search panel
    this.$panel.classList.add('header__panel--open');
    this.$header.classList.add('header--panel-open');

    // Add keydown to body
    document.body.addEventListener('keydown', this.onKeydownBinded);
    this.$panel.addEventListener('click', this.bindedPanelClick);
    this.$closeButton.addEventListener('click', this.bindedCloseClick);

    // Disable scrolling
    disableBodyScroll(this.$panel);

    // Enable focus trap
    this.focusTrap.activate();
  }
}
