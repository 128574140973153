import Slider from 'javascripts/globals/slider';

const componentStore = [];

document.querySelectorAll('.person-box-slider').forEach(($slider) => {
  componentStore.push(new Slider($slider, {
    sliderFrame: 'person-box-slider__frame',
    slidesContainer: 'person-box-slider__items',
    sliderSlide: 'person-box-slider__item',
    sliderControlsframe: 'person-box-slider__controls',
    sliderControlsframemethod: 'prepend',
  }));
});
