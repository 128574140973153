import h from 'hyperscript';
import icon from 'components/_particles/icon/icon';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUiDefault from 'photoswipe/dist/photoswipe-ui-default';

// PhotoSwipe template
const $photoswipeTemplate = h('.pswp', {
  attrs: {
    tabindex: '-1',
    role: 'dialog',
    'aria-hidden': 'true',
  },
}, h('.pswp__bg'), h('.pswp__scroll-wrap',
  h('.pswp__container',
    h('.pswp__item'),
    h('.pswp__item'),
    h('.pswp__item')),
  h('.pswp__ui.pswp__ui--hidden',
    h('.pswp__top-bar',
      h('.pswp__counter'),
      h('button.pswp__button.pswp__button--close', { title: 'Schließen (Esc)' },
        icon({ icon: 'close' }),
        h('span.u-hidden-visually', 'Schließen (Esc)')),
      h('.pswp__preloader',
        h('.pswp__preloader__icn',
          h('.pswp__preloader__cut',
            h('.pswp__preloader__donut'))))),
    h('button.pswp__button.pswp__button--arrow--left', { title: 'Vorheriges Bild (Pfeil links)' },
      icon({ icon: 'arrow-left' }),
      h('span.u-hidden-visually', 'Vorheriges Bild')),
    h('button.pswp__button.pswp__button--arrow--right', { title: 'Nächstes Bild (Pfeil rechts)' },
      icon({ icon: 'arrow-right' }),
      h('span.u-hidden-visually', 'Nächstes Bild')),
    h('.pswp__caption',
      h('.pswp__caption__center')))));

// Create array of gallery items
const createGalleryItems = $thumbs => $thumbs.map(
  ($thumb) => {
    // Get image size
    const $figure = $thumb.closest('.figure');
    const size = $thumb.getAttribute('data-size').split('x');

    // Check for caption/copyright
    let title;
    const $caption = $figure ? $figure.querySelector('.figure__caption-text') : null;
    const $copyright = $figure ? $figure.querySelector('.tooltip__text') : null;
    const $download = $figure ? $figure.querySelector('.figure__download') : null;

    if (!$download) {
      if ($caption && !$copyright) {
        title = `<span class='f-w-bold'>${$caption.textContent}</span>`;
      } else if ($caption && $copyright) {
        title = `<span class='f-w-bold'>${$caption.textContent}</span> (${$copyright.textContent})`;
      }
    } else if ($download) {
      if ($caption && !$copyright) {
        title = `${$download.outerHTML} <span class='f-w-bold'>${$caption.textContent}</span>`;
      } else if ($caption && $copyright) {
        title = `${$download.outerHTML} <span class='f-w-bold'>${$caption.textContent}</span> (${$copyright.textContent})`;
      }
    }

    return {
      src: $thumb.getAttribute('href'),
      w: parseInt(size[0], 10),
      h: parseInt(size[1], 10),
      title,
    };
  },
);

// Attach photoswipe to items
const attachPhotoswipe = ($thumbs, galleryItems) => $thumbs.forEach(
  ($thumb, index) => {
    $thumb.addEventListener('click', (event) => {
      event.preventDefault();

      // Insert template
      const $template = $photoswipeTemplate.cloneNode(true);
      document.body.appendChild($template);

      // Init PhotoSwipe
      const slideshow = new PhotoSwipe(
        $template,
        PhotoSwipeUiDefault,
        galleryItems,
        {
          index,
          closeOnScroll: false,
          closeOnVerticalDrag: false,
          mouseUsed: true,
        },
      );

      // After gallery is closed, destroy DOM
      slideshow.listen('destroy', () => $template.remove());

      // Start gallery
      slideshow.init();
    });
  },
);

const initLightbox = ($thumbs) => {
  if (!$thumbs || !$thumbs.length) {
    return;
  }

  attachPhotoswipe($thumbs, createGalleryItems($thumbs));
};

export default initLightbox;
