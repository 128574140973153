function optOutInit() {
  const getConsentCookie = (name) => {
    const value = `;${document.cookie}`;
    const parts = value.split(`;${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  };

  document.querySelectorAll('.js-opt-out').forEach(($optOut) => {
    const $input = $optOut.querySelector('.checkbox__input');
    const cookieName = $input.getAttribute('data-cookie');
    const $textActivated = $optOut.querySelector('.opt-activated');
    const $textDeactivated = $optOut.querySelector('.opt-deactivated');

    function setState(state) {
      if (state === true) {
        document.cookie = `${cookieName}=accept`;
        $textActivated.style.display = 'inline-block';
        $textDeactivated.style.display = 'none';
        $input.setAttribute('checked', true);
      } else {
        document.cookie = `${cookieName}=decline`;
        $textActivated.style.display = 'none';
        $textDeactivated.style.display = 'inline-block';
        $input.removeAttribute('checked');
      }
    }

    function submit() {
      if ($input.checked) {
        setState(true);
      } else {
        setState(false);
      }
    }

    if (getConsentCookie(cookieName) === 'accept') {
      setState(true);
    } else {
      setState(false);
    }

    $input.addEventListener('change', () => submit());
    $input.removeAttribute('disabled');
  });
}

optOutInit();
