import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import focusTrap from 'focus-trap';

/*
 * overlay.js
 */

export default class Overlay {
  constructor(config) {
    this.config = {
      isDesktop: true,
      ...config,
    };
    this.isDesktop = this.config.isDesktop;
    this.hasGlobalConsent = false;
    this.$el = this.config.$el;
    this.$template = this.$el.querySelector('.js-overlay-template');

    if (this.getConsentCookie(this.config.consentCookieName) === 'accept') {
      this.hasGlobalConsent = true;
    }
  }

  getConsentCookie(name) {
    const value = `;${document.cookie}`;
    const parts = value.split(`;${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  }

  init() {
    if (this.getConsentCookie(this.config.consentCookieName) === 'accept') {
      this.hasGlobalConsent = true;
    }
    if (!this.hasGlobalConsent) {
      this.initOverlay();
    } else {
      this.consent();
    }
  }

  initOverlay(create = true) {
    clearAllBodyScrollLocks();

    if (!this.$el) {
      const $overlays = document.querySelectorAll('.js-overlay');
      $overlays.forEach(($overlay) => {
        // remove overlay
        if ($overlay && $overlay.parentNode && !create) {
          $overlay.parentNode.removeChild($overlay, $overlay.parentNode);
        }
      });
    } else if (this.$el) {
      const $overlayCurrent = this.$el.querySelector('.js-overlay');
      if ($overlayCurrent) {
        $overlayCurrent.parentNode.removeChild($overlayCurrent, $overlayCurrent.parentNode);
      }
    }

    // Kill focus trap
    // MISSING DESCRIPTION
    if (this.focusTrap) {
      this.focusTrap.deactivate();
      this.focusTrap = null;
    }

    if (this.$template && create) {
      // insert overlay
      const $clone = document.importNode(this.$template.content, true);

      this.$template.parentNode.appendChild($clone);

      if (this.isDesktop) {
        // this.$template.parentNode.appendChild($clone);
      } else {
        // document.body.appendChild($clone, true);
        disableBodyScroll($clone);
      }

      // add button / checkbox events
      this.$decline = this.$el.querySelector('.js-overlay-decline');
      this.$consent = this.$el.querySelector('.js-overlay-consent');
      this.$globalConsent = this.$el.querySelector('.js-overlay-global-consent');

      if (this.$decline) {
        this.$decline.addEventListener('click', this.declineConsent);
      }

      if (this.$consent) {
        this.$consent.addEventListener('click', this.consent);
      }

      // MISSING DESCRIPTION: Focus trap
      const $openOverlay = this.$el.querySelector('.js-overlay');
      $openOverlay.querySelector('.overlay__title').setAttribute('tabindex', '0');
      this.focusTrap = focusTrap($openOverlay, {
        escapeDeactivates: false,
        clickOutsideDeactivates: true,
        initialFocus: $openOverlay.querySelector('.overlay__title'),
      });

      // Enable focus trap
      if (this.isDesktop) {
        this.focusTrap.deactivate();
      } else {
        this.focusTrap.activate();
      }

      if (this.config.onOverlayInit) {
        this.config.onOverlayInit();
      }
    }
  }

  destroy() {
    this.initOverlay(false);
    clearAllBodyScrollLocks();
  }

  declineConsent = (event) => {
    if (event) {
      event.stopPropagation();
    }
    this.initOverlay(false);
    if (this.config.onDecline) {
      this.config.onDecline();
    }
  }

  consent = (event) => {
    if (event) {
      event.stopPropagation();
    }

    const hasCheckedGlobalConsent = this.$globalConsent && this.$globalConsent.checked;

    if (hasCheckedGlobalConsent) {
      document.cookie = `${this.config.consentCookieName}=accept`;
      this.hasGlobalConsent = true;
      if (this.config.onGlobalConsent) {
        this.config.onGlobalConsent();
      }
      this.initOverlay(false);

      if (this.config.onConsent) {
        this.config.onConsent(event);
      }
    } else {
      this.initOverlay(false);
      if (this.config.onConsent) {
        this.config.onConsent(event);
      }
    }
  }
}
