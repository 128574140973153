import h from 'hyperscript';
import Overlay from 'javascripts/globals/overlay';

class Iframe {
  constructor($el) {
    this.$el = $el;

    this.Overlay = new Overlay({
      onConsent: this.onConsent,
      consentCookieName: 'avh-cookie-gmaps',
      $el: this.$el,
    });

    if (document.cookie.split(';').filter(item => item.indexOf('avh-cookie-gmaps=accept') >= 0).length) {
      this.Overlay.consent();
    } else {
      this.Overlay.init();
    }
  }

  getIframe($el) {
    const options = {
      src: $el.getAttribute('data-src'),
      height: $el.getAttribute('data-height'),
      allowfullscreen: $el.getAttribute('data-allowfullscreen') === 'true',
    };

    return h('iframe.iframe__tag', {
      src: options.src,
      height: options.height,
      width: '100%',
      frameBorder: '0',
      allowFullscreen: options.allowFullscreen,
      msAllowFullscreen: options.allowFullscreen,
      webkitAllowFullscreen: options.allowFullscreen,
      mozAllowFullscreen: options.allowFullscreen,
    });
  }

  onConsent = () => {
    const $iframes = document.querySelectorAll('.iframe');

    $iframes.forEach(($iframe) => {
      const $overlay = $iframe.querySelector('.iframe__overlay');
      // remove overlay
      if ($overlay) {
        $iframe.removeChild($overlay, $iframe);
      }

      // Add iFrame if not already
      if (!$iframe.querySelector('.iframe__tag')) {
        const $iframeHTML = this.getIframe($iframe);
        $iframe.appendChild($iframeHTML);
      }
    });
  }
}

document.querySelectorAll('.iframe').forEach($iframe => new Iframe($iframe));
