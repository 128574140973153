// Check if etracker script is loaded
if (!(typeof _etracker === 'undefined')) {
  // Check for elements to track
  document.querySelectorAll('[data-etracker]').forEach(($elem) => {
    $elem.addEventListener('click', () => {
      // Get location path
      const path = document.location.pathname;

      // Get data attributes
      const object = $elem.dataset.etrackerObject || path;
      const category = $elem.dataset.etrackerCategory || false;
      const action = $elem.dataset.etrackerAction || false;
      const type = $elem.dataset.etrackerType || false;

      // Send user defined event
      // eslint-disable-next-line
      _etracker.sendEvent(new et_UserDefinedEvent(object, category, action, type));
    });
  });
}
