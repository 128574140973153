import { BREAKPOINTS } from 'javascripts/constants';
import Masonry from 'masonry-layout';
import h from 'hyperscript';

function initShowMore($newsroomGrid, $items) {
  const $itemCount = $items.length;

  if ($itemCount > 10) {
    const $button = h('button.button.button--secondary.button--decoration.grid__show-more-button', h('span.button__text', 'Alle anzeigen'));

    $button.addEventListener('click', () => {
      $newsroomGrid.classList.add('newsroom-grid--open');
      // Hide Load-More button
      $button.style.display = 'none';
    }, false);

    $newsroomGrid.appendChild(h('.newsroom-grid__show-more', $button));
  }
}

const Grid = ($newsroomGrid) => {
  // Get references
  const $items = $newsroomGrid.querySelector('.newsroom-grid__items');
  const addShowMore = $newsroomGrid.classList.contains('newsroom-grid--showmore');

  // Masonry grid
  const masonry = () => {
    const $item = '.newsroom-grid__item';
    const mqm = window.matchMedia(`(min-width: ${BREAKPOINTS.m})`);

    if (mqm) {
      const masonryGrid = new Masonry($items, {
        itemSelector: $item,
        columnWidth: $item,
        gutter: 35,
      });

      $items.addEventListener('lazyloaded', () => {
        masonryGrid.layout();
      });
    }
  };

  if ($items) {
    // Init Masonry
    masonry();

    // Init Masonry again on resize
    window.addEventListener('resize', masonry);

    if (addShowMore) {
      initShowMore($newsroomGrid, $items.querySelectorAll('.newsroom-grid__item'));
    }
  }

  // Init masonry when tabs are changed
  const $newsroomTabs = document.querySelector('.js-tabs');

  if ($newsroomTabs && $newsroomTabs.contains($newsroomGrid)) {
    $newsroomTabs.querySelectorAll('.js-tablist__link').forEach(($tab) => {
      ['click', 'focus'].forEach(e => $tab.addEventListener(e, masonry, false));
    });
  }
};

document.querySelectorAll('.newsroom-grid').forEach($newsroomGrid => Grid($newsroomGrid));
