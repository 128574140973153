import focusTrap from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import h from 'hyperscript';

export default class DisciplineSelector {
  constructor($disciplineSelector) {
    this.$el = $disciplineSelector;
    this.$button = $disciplineSelector.querySelector('.discipline-selector__button');
    this.$panel = document.getElementById(this.$button.getAttribute('aria-controls'));
    this.$display = $disciplineSelector.querySelector('.discipline-selector__display');
    this.$applyButton = this.$panel.querySelector('.discipline-selector__apply');
    this.$closeButton = this.$panel.querySelector('.discipline-selector__close');
    this.$disciplineToggles = this.$panel.querySelectorAll('.discipline-selector__toggle');

    // Binded events
    this.bindedClick = this.toggle.bind(this);
    this.bindedPanelClick = this.panelClick.bind(this);
    this.bindedApplyClick = this.disciplinesApply.bind(this);
    this.bindedCloseClick = this.closePanel.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);
    this.bindedDisciplineToggle = this.disciplineToggle.bind(this);

    this.$button.addEventListener('click', this.bindedClick);
    this.$applyButton.addEventListener('click', this.bindedApplyClick);
    this.$closeButton.addEventListener('click', this.bindedCloseClick);
    this.$disciplineToggles.forEach(($el) => {
      $el.addEventListener('click', this.bindedDisciplineToggle);
    });

    // Check for initially checked disciplines
    this.disciplinesApply();

    // Init focus trap
    this.focusTrap = focusTrap(this.$panel, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
    });
  }

  toggle(event) {
    event.preventDefault();

    // Get current state of panel
    const isClosed = this.$button.getAttribute('aria-expanded') !== 'true';

    // Switch state
    if (isClosed) {
      this.openPanel();
    } else {
      this.closePanel();
    }
  }

  disciplinesApply(event) {
    if (event) {
      event.preventDefault();
    }

    this.$display.innerHTML = '';

    this.$checkedOptions = this.$panel.querySelectorAll('input[type="checkbox"]:checked');
    if (this.$checkedOptions) {
      const $disciplineList = h('li.discipline-selector__display-list');
      this.$checkedOptions.forEach(($el) => {
        $disciplineList.append(
          h('li.discipline-selector__display-list-item', $el.parentNode.querySelector('.checkbox__label').textContent),
        );
      });
      this.$display.append($disciplineList);
    }

    this.closePanel();
  }

  disciplineToggle(event) {
    const $target = event.currentTarget;

    if ($target) {
      const disciplineVisible = $target.getAttribute('aria-expanded') === 'true';
      const $content = document.getElementById($target.getAttribute('aria-controls'));

      // Switch aria state
      $target.setAttribute('aria-expanded', (!disciplineVisible).toString());

      const contentClass = $content.classList[0];
      // Switch content visiblity
      if (disciplineVisible) {
        $content.classList.remove(`${contentClass}--visible`);
      } else {
        $content.classList.add(`${contentClass}--visible`);
      }
    }
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePanel();
    }
  }

  panelClick(event) {
    if (event.target.closest('.discipline-selector__panel-inner')) return;

    this.closePanel();
  }

  closePanel(event) {
    if (event) {
      event.preventDefault();
    }

    // Change toggle button
    this.$button.setAttribute('aria-expanded', 'false');

    // Close Search Panel
    this.$panel.classList.remove('discipline-selector__panel--open');

    // Remove keydown from body
    document.body.removeEventListener('keydown', this.onKeydownBinded);
    this.$panel.removeEventListener('click', this.bindedPanelClick);
    this.$closeButton.removeEventListener('click', this.bindedCloseClick);

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
  }

  openPanel() {
    // Change toggle button
    this.$button.setAttribute('aria-expanded', 'true');

    // Show search panel
    this.$panel.classList.add('discipline-selector__panel--open');

    // Add keydown to body
    document.body.addEventListener('keydown', this.onKeydownBinded);
    this.$panel.addEventListener('click', this.bindedPanelClick);
    this.$closeButton.addEventListener('click', this.bindedCloseClick);

    // Disable scrolling
    disableBodyScroll(this.$panel);

    // Enable focus trap
    this.focusTrap.activate();
  }
}

// Discipline Selector toggle button
document.querySelectorAll('.discipline-selector').forEach($element => new DisciplineSelector($element));
