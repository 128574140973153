import Slider from 'javascripts/globals/slider';

class CircleBoxSlider extends Slider {
  constructor($el, options) {
    super($el, options);

    this.isHorizontal = this.$el.classList.contains('circle-box--horizontal');
    this.isInGrid = this.$el.closest('.grid--50-50, .grid--50-50-big-modules');
    this.$headline = this.$el.querySelector('.circle-box__headline');
    this.isM = window.matchMedia('(min-width: 768px)');
    this.isL = window.matchMedia('(min-width: 1024px)');

    if (this.isHorizontal) window.addEventListener('resize', this.onResizeHorizontal);

    if (this.isHorizontal && this.isM.matches) {
      this.adjustContent();
    } else if (this.isHorizontal) {
      this.deinitHorizontal();
    }
  }

  adjustContent() {
    if (this.isL.matches && this.isInGrid) {
      return;
    }

    const $container = this.$el.querySelector('.circle-box__items');
    const containerRect = $container.getBoundingClientRect();

    const headlineRect = this.$headline.getBoundingClientRect();

    const $text = this.$el.querySelector('.circle-box__text-inner');
    const textRect = $text.getBoundingClientRect();

    const threshold = 42;
    const headlineLeftValue = textRect.left - containerRect.left;
    let justifyValue = (
      (containerRect.height - (headlineRect.height + textRect.height + threshold)) / 2
    );

    if (justifyValue < (threshold + threshold / 2)) {
      justifyValue = threshold / 2;
    }

    this.$headline.style.left = `${headlineLeftValue}px`;
    this.$headline.style.top = `${justifyValue}px`;

    this.$el.querySelectorAll('.circle-box__text').forEach(($textBox) => {
      $textBox.setAttribute('style', `margin-bottom: ${justifyValue}px;`);
    });
  }

  deinitHorizontal() {
    this.$headline.removeAttribute('style');

    this.$el.querySelectorAll('.circle-box__text').forEach(($textBox) => {
      $textBox.removeAttribute('style');
    });
  }

  onResizeHorizontal = () => {
    if (this.isM.matches && !(this.isL.matches && this.isInGrid)) {
      this.adjustContent();
    } else {
      this.deinitHorizontal();
    }
  }
}

document.querySelectorAll('.circle-box.js-slider').forEach($circleBox => new CircleBoxSlider($circleBox, {
  sliderFrame: 'circle-box__inner',
  slidesContainer: 'circle-box__items',
  sliderSlide: 'circle-box__item',
  sliderControlsframe: 'circle-box__controls',
  sliderControlsframemethod: 'prepend',
  scrollToActiveSlide: true,
  controlIconLeft: 'hook-left',
  controlIconRight: 'hook-right',
}));
