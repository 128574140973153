import Slider from 'javascripts/globals/slider';

class ChartSlider extends Slider {
  constructor($el, config) {
    super($el, config);
    this.$el = $el;
    this.$controls = this.$el.querySelector('.chart-slider__controls');
  }
}

const componentStore = [];

document.querySelectorAll('.chart-slider').forEach(($chartSlider) => {
  componentStore.push(new ChartSlider($chartSlider, {
    sliderFrame: 'chart-slider__frame',
    slidesContainer: 'chart-slider__items',
    sliderSlide: 'chart-slider__item',
    sliderControlsframe: 'chart-slider__controls',
    sliderControlsframemethod: 'prepend',
  }));
});
