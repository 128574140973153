import getVideoId from 'get-video-id';
import h from 'hyperscript';
import { BREAKPOINTS } from 'javascripts/constants';
import Overlay from 'javascripts/globals/overlay';
import plyrConfig from 'javascripts/utils/plyr-config';
import plyr from 'plyr';
import initLightbox from './lightbox';

export default class Figure {
  constructor($el, config, mql) {
    this.config = {
      wrapperClass: 'figure__media',
      playLinkClass: 'figure__play',
      ...config,
    };
    this.$el = $el;
    this.$media = $el.querySelector(`.${this.config.wrapperClass}`);
    this.$playLink = $el.querySelector(`.${this.config.playLinkClass}`);
    this.isDesktop = mql.matches;

    this.Overlay = new Overlay({
      isDesktop: this.isDesktop,
      onOverlayInit: this.onInitOverlay,
      onDecline: this.onDeclineConsent,
      onConsent: this.onConsent,
      consentCookieName: 'avh-cookie-youtube',
      $el: this.$el,
    });

    if (this.$media) {
      this.$media.addEventListener('click', this.playVideo);
      this.initFigureHover();
    }
  }

  playVideo = (event) => {
    if (document.cookie.split(';').filter(
      item => item.indexOf('avh-cookie-youtube=accept') >= 0,
    ).length) {
      event.preventDefault();
      event.stopPropagation();
      this.onConsent();
      this.Overlay.consent();
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.$media.removeEventListener('click', this.playVideo);
      this.Overlay.init();
    }
  }

  onInitOverlay = () => {
    this.initFigureHover(false);
  }

  removeCopyright() {
    const $copyButton = this.$el.querySelector('.copyright');
    const $copyTooltip = this.$el.querySelector('.tooltip__popup');
    if ($copyButton) {
      $copyButton.parentNode.removeChild($copyButton, $copyButton.parentNode);
    }

    if ($copyTooltip) {
      $copyTooltip.parentNode.removeChild($copyTooltip, $copyTooltip.parentNode);
    }
  }

  onDeclineConsent = () => {
    if (this.$media) {
      this.$media.addEventListener('click', this.playVideo);
      this.initFigureHover();
    }
  }

  onConsent = () => {
    this.removeCopyright();
    const { id, service } = getVideoId(this.$playLink.href);

    if (id && service === 'youtube') {
      // Construct Youtube iframe player, add rel=0 to disable related videos
      const $iframe = h(
        '.figure__video-wrapper',
        h('iframe.figure__video', {
          src: `https://www.youtube-nocookie.com/embed/${id}?rel=0&autoplay=1`,
          allowFullscreen: true,
          msAllowFullscreen: true,
          webkitAllowFullscreen: true,
          mozAllowFullscreen: true,
        }),
      );

      // Replace with iframe
      if (this.$media.parentNode) {
        this.$media.parentNode.replaceChild($iframe, this.$media);
      }
    }
  }

  initFigureHover(create = true) {
    ['mouseover', 'mouseout']
      .forEach(
        type => this.$media.removeEventListener(type, type === 'mouseover' ? this.onMouseOver : this.onMouseOut),
      );

    this.onMouseOut();

    if (this.$playLink && create) {
      this.$media.addEventListener('mouseover', this.onMouseOver);
      this.$media.addEventListener('mouseout', this.onMouseOut);
    }
  }

  onMouseOver = () => {
    this.$playLink.classList.add('active-overlay');
    this.$media.style.cursor = 'pointer';
  }

  onMouseOut = () => {
    this.$playLink.classList.remove('active-overlay');
    this.$media.removeAttribute('style');
  }

  deconstructor() {
    this.Overlay.destroy();
  }
}

// external Video
const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.m})`);

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];

  document
    .querySelectorAll('.js-figure')
    .forEach(($figure) => {
      componentStore.push(new Figure($figure, {}, mql));
    });
}

mql.addListener(onMediaQueryChange);
onMediaQueryChange();

// internal Video
plyr.setup(document.querySelectorAll('.figure__media--video'), plyrConfig);

// lightbox
initLightbox(Array.prototype.slice.call(document.querySelectorAll('.js-lightbox')));
