import h from 'hyperscript';
import icon from '../../_particles/icon/icon';

document.querySelectorAll('.js-tag-group').forEach(($tagGroup) => {
  const $list = $tagGroup.querySelector('.tag-group__list');
  const activeClass = 'tag-group--collapsible-active';

  if ($list.children.length > 9) {
    const $moreButton = h('li.tag-group__show', h('button.tag-group__button', { title: 'Alle anzeigen' }, icon({ icon: 'plus' })));

    $list.appendChild($moreButton);

    $moreButton.addEventListener('click', () => {
      $tagGroup.classList.add(activeClass);
      $moreButton.remove();
    });
  }

  $tagGroup.classList.add('tag-group--collapsible-initialized');
});
