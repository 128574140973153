export default {
  loadSprite: false,
  i18n: {
    restart: 'Neustarten',
    rewind: '{seektime} Sekunden zurückspulen',
    play: 'Abspielen',
    pause: 'Pausieren',
    forward: '{seektime} Sekunden vorspulen',
    buffered: 'vorgeladen',
    currentTime: 'Aktuelle Abspielzeit',
    duration: 'Länge',
    volume: 'Lautstärke',
    mute: 'Stummschalten',
    unmute: 'Stummschaltung aufheben',
    toggleMute: 'Ton an-/abschalten',
    toggleCaptions: 'Untertitel an-/abschalten',
    toggleFullscreen: 'Vollbild an-/abschalten',
  },
  iconPrefix: 'icon',
  controls: [
    'play-large',
    'play',
    'progress',
    'current-time',
    'mute',
    'volume',
    'captions',
    'fullscreen',
  ],
};
