import Headroom from 'headroom.js';
import throttle from 'javascripts/utils/throttle';
import NavigationOverlay from '../navigation/navigation.overlay';

export default class HeaderSticky {
  constructor($panel) {
    this.$page = document.querySelector('.page');
    this.$header = document.querySelector('.header');
    this.$navigation = document.querySelector('.navigation');
    this.$panel = $panel;
    this.navigationWasFlyout = false;

    // Change point
    if (this.$header.classList.contains('header--login') && !this.$header.querySelector('.header__breadcrumb')) {
      this.change = 135;
    } else {
      this.change = 155;
    }

    // Binded events
    this.bindedScroll = throttle(this.scroll.bind(this));

    // Add events
    document.addEventListener('scroll', this.bindedScroll, { passive: true });

    // const headerHeight = this.$header.offsetHeight;
    // this.$page.style.paddingTop = `${headerHeight}px`;

    // Check for initial scrollposition
    this.scroll();

    // Init headroom
    this.headroom = new Headroom(this.$header, {
      offset: 500,
      classes: {
        // when element is initialised
        initial: 'header__headroom',
        // when scrolling up
        pinned: 'header__headroom--pinned',
        // when scrolling down
        unpinned: 'header__headroom--unpinned',
        // when above offset
        top: 'header__headroom--top',
        // when below offset
        notTop: 'header__headroom--not-top',
        // when at bottom of scroll area
        bottom: 'header__headroom--bottom',
        // when not at bottom of scroll area
        notBottom: 'header__headroom--not-bottom',
        // when frozen method has been called
        frozen: 'header__headroom--frozen',
      },
    });
    this.headroom.init();
  }

  deconstructor() {
    // Remove events
    document.removeEventListener('scroll', this.bindedScroll);
  }

  scroll() {
    const scroll = window.scrollY || window.pageYOffset;
    const navigationClasses = this.$navigation.classList;

    if (scroll >= this.change) {
      this.$header.classList.add('header--sticky');

      if (navigationClasses.contains('navigation--flyout')) {
        this.navigationWasFlyout = true;
        this.$navigation.classList.remove('navigation--flyout');

        this.$navigation.classList.add('navigation--overlay');

        this.navigationOverlay = new NavigationOverlay(this.$navigation);
        this.navigationOverlay.init();
      }
    } else if (scroll < this.change) {
      this.$header.classList.remove('header--sticky');

      if (this.navigationWasFlyout) {
        this.navigationOverlay.deinit();
        this.$navigation.classList.add('navigation--flyout');
      }
    }
  }
}
