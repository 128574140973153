import Slider from 'javascripts/globals/slider';

document
  .querySelectorAll('.publication-box')
  .forEach($slider => new Slider($slider, {
    sliderFrame: 'publication-box__slider',
    slidesContainer: 'publication-box__items',
    sliderSlide: 'publication-box__item',
    sliderControlsframe: 'publication-box__controls',
  }));
