import getTarget from 'javascripts/utils/get-target';
import unvisibleFocus from 'javascripts/utils/unvisible-focus';

export default class NavigationOverlay {
  dontFocusBackToMoveLink = true;

  constructor($navigation) {
    this.$navigation = $navigation;
    this.$main = $navigation.querySelector('.navigation__items--main');
    this.$inner = $navigation.querySelector('.navigation__inner');

    // Events
    this.onMoveLinkClickBinded = this.onMoveLinkClick.bind(this);
    this.onBackLinkClickBinded = this.onBackLinkClick.bind(this);
    this.onFocusinBinded = this.onFocusin.bind(this);
  }

  init() {
    this.$navigation.addEventListener('click', this.onMoveLinkClickBinded);
    this.$navigation.addEventListener('click', this.onBackLinkClickBinded);
    this.$navigation.addEventListener('focusin', this.onFocusinBinded);

    this.$navigation.classList.add('navigation--overlay');

    this.$reference = false;

    // Add aria attributes
    this.$navigation
      .querySelectorAll('.navigation__item-link.navigation__move, .navigation__submenu-item-link.navigation__move')
      .forEach($el => $el.setAttribute('aria-expanded', 'false'));
  }

  deinit() {
    this.$navigation.removeEventListener('click', this.onMoveLinkClickBinded);
    this.$navigation.removeEventListener('click', this.onBackLinkClickBinded);
    this.$navigation.removeEventListener('focusin', this.onFocusinBinded);

    // Reset navigation height
    this.$navigation.style.height = '';
    // Reset navigation classnames
    this.$navigation.classList.remove('navigation--overlay');
    this.$navigation.classList.remove('navigation--level-1');
    this.$navigation.classList.remove('navigation--level-2');
    // this.$navigation.classList.add('navigation');

    // Remove inline styles
    this.$main.removeAttribute('style');

    // Find open submenus
    this.$navigation.querySelectorAll('.navigation__submenu--current').forEach(($submenu) => {
      $submenu.classList.remove('navigation__submenu--current');
    });

    // Remove aria attributes
    this.$navigation
      .querySelectorAll('.navigation__item-link.navigation__move, .navigation__submenu-item-link.navigation__move')
      .forEach($el => $el.removeAttribute('aria-expanded'));
  }

  adjustNavigationHeight() {
    if (this.$navigation.classList.contains('navigation--level-2')) {
      this.$reference = this.$navigation.querySelector('.navigation__submenu--current');
    }

    if (this.$reference) {
      const dimensions = this.$reference.getBoundingClientRect();

      if (dimensions.height > 0) {
        this.$main.style.height = `${dimensions.height}px`;

        if (dimensions.height + 235 > window.innerHeight) {
          this.$inner.style.height = `${dimensions.height + 245}px`;
        }
      } else {
        this.$main.removeAttribute('style');
        this.$inner.removeAttribute('style');
      }
    } else {
      this.$main.removeAttribute('style');
      this.$inner.removeAttribute('style');
    }
  }

  onMoveLinkClick(event) {
    const $moveLink = getTarget(event.target, 'button.navigation__move');

    if ($moveLink) {
      event.preventDefault();

      const $submenu = $moveLink.parentNode.querySelector('.navigation__submenu');

      // Already active?
      if ($submenu.classList.contains('navigation__submenu--current')) {
        return;
      }

      // Make submenu visible
      $submenu.classList.add('navigation__submenu--current');

      // Move 1 level down
      this.$navigation.classList.add('navigation--level-2');

      // Set aria attribute
      $moveLink.setAttribute('aria-expanded', 'true');

      // Adjust height
      this.adjustNavigationHeight();
    }
  }

  onBackLinkClick(event) {
    const $backLink = getTarget(event.target, '.navigation__back');

    if ($backLink) {
      event.preventDefault();

      // Get elements
      const $submenu = $backLink.closest('.navigation__submenu');
      const $item = $submenu.closest('.navigation__submenu-item, .navigation__item');

      // Move 1 level up
      this.$navigation.classList.remove('navigation--level-2');

      // Hide submenu
      $submenu.classList.remove('navigation__submenu--current');

      // Remove aria attribute
      const $moveLink = $item.querySelector('.navigation__move');
      $moveLink.setAttribute('aria-expanded', 'false');

      // Set focus to move link back
      if (!this.dontFocusBackToMoveLink) {
        unvisibleFocus($moveLink);
      }

      // Adjust height
      this.adjustNavigationHeight();

      // Reset
      this.dontFocusBackToMoveLink = false;
    }
  }

  onFocusin(event) {
    const $level1 = getTarget(event.target, '.navigation__item-link');
    const $level2 = getTarget(event.target, '.navigation__submenu--level-2 > .navigation__submenu-item > .navigation__submenu-item-link');
    const $winner = $level1 || $level2 || false;

    if ($winner) {
      if ($level1 && this.$navigation.classList.contains('navigation--level-2')) {
        // Find open submenu
        const allOpenSubmenu = Array.from(this.$navigation.querySelectorAll('.navigation__submenu--current'));

        // Don't focus back to the back link
        this.dontFocusBackToMoveLink = true;

        // Close all
        if ($level1) {
          allOpenSubmenu.reverse().forEach(($submenu) => {
            const $backLink = $submenu.querySelector('.navigation__back');
            $backLink.click();
          });

          // Close last one
        } else {
          const $submenu = allOpenSubmenu[allOpenSubmenu.length - 1];
          const $backLink = $submenu.querySelector('.navigation__back');
          $backLink.click();
        }

        // Focus back
        unvisibleFocus($winner);
      }
    }
  }
}
