import h from 'hyperscript';
import { BREAKPOINTS } from 'javascripts/constants';
import Slider from 'javascripts/globals/slider';

const mql = window.matchMedia(`(max-width: ${BREAKPOINTS.l})`);

class Stage {
  constructor($el) {
    this.$el = $el;
    this.$slider = this.$el.querySelector('.stage__slider');
    this.$items = this.$el.querySelectorAll('.stage__item');
    this.$controlsAltPrev = this.$el.querySelector('.stage__controls-alt-button--prev');
    this.$controlsAltNext = this.$el.querySelector('.stage__controls-alt-button--next');

    this.slider = new Slider($el, {
      sliderFrame: 'stage__slider',
      slidesContainer: 'stage__items',
      sliderSlide: 'stage__item',
      sliderControlsframe: 'stage__controls',
      autoplay: true,
      autoPlayTime: 10000,
    });

    if (this.$items.length) {
      this.initControls();
      this.initEvents();
      this.setControlsHeight();
    }
  }

  initControls() {
    const $prevButton = h(
      'button.stage__controls-alt-button.stage__controls-alt-button--prev',
      { attrs: { tabindex: -1 } },
      h('img', {
        src: `${window.alex.ASSET_BASE_URL}images/hook-left-shaded.svg`,
        width: '30',
        height: '30',
        alt: 'Vorheriger Inhalt',
      }),
      {
        type: 'button',
        onclick: () => {
          this.slider.lory.prev();
        },
      },
    );

    const $nextButton = h(
      'button.stage__controls-alt-button.stage__controls-alt-button--next',
      { attrs: { tabindex: -1 } },
      h('img', {
        src: `${window.alex.ASSET_BASE_URL}images/hook-right-shaded.svg`,
        width: '30',
        height: '30',
        alt: 'Nächster Inhalt',
      }),
      {
        type: 'button',
        onclick: () => {
          this.slider.lory.next();
        },
      },
    );

    this.$slider.append(
      h('.stage__controls-alt', $prevButton, $nextButton),
    );
  }

  initEvents() {
    window.addEventListener('resize', this.setControlsHeight);
  }

  setControlsHeight = () => {
    if (mql.matches) {
      const newHeight = this.$el.querySelector('.figure__media').offsetHeight - 88;

      this.$el.querySelector('.stage__controls-alt').style.height = `${newHeight}px`;
    }
  }
}

document
  .querySelectorAll('.stage')
  .forEach($el => new Stage($el));
