import Choices from 'choices.js';

document.querySelectorAll('.js-choices').forEach((e) => {
  const choices = new Choices(e, { // eslint-disable-line
    searchFloor: 2,
    itemSelectText: '',
    searchPlaceholderValue: 'Suchen',
    searchFields: ['label'],
    classNames: {
      containerOuter: 'choices select',
    },
    noResultsText: 'Keine Einträge gefunden',
  });
});
