import Slider from 'javascripts/globals/slider';
import { BREAKPOINTS } from 'javascripts/constants';

const mql = [
  window.matchMedia(`(min-width: ${BREAKPOINTS.m})`),
  window.matchMedia(`(min-width: ${BREAKPOINTS.l})`),
];

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];
  const isL = mql[1].matches;
  const isM = mql[0].matches;

  let slidesToScroll = 1;

  if (isL) {
    slidesToScroll = 3;
  } else if (isM) {
    slidesToScroll = 2;
  }

  document
    .querySelectorAll('.article-teaser-row')
    .forEach(($slider) => {
      const $items = $slider.querySelectorAll('.article-teaser-row__item');

      if ($items && ($items.length <= 3) && isL) {
        return;
      }

      componentStore.push(new Slider($slider, {
        sliderFrame: 'article-teaser-row__frame',
        slidesContainer: 'article-teaser-row__items',
        sliderSlide: 'article-teaser-row__item',
        sliderControlsframe: 'article-teaser-row__controls',
        sliderControlsframemethod: 'prepend',
        slidesToScroll,
        slidesFocusable: false,
      }));
    });
}

mql.forEach(((el) => {
  el.addListener(onMediaQueryChange);
}));

onMediaQueryChange();
