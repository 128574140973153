import { BREAKPOINTS } from 'javascripts/constants';
import Overlay from 'javascripts/globals/overlay';

const componentWrapperClass = 'js-chart';

function initMap() {
  /* eslint-disable */
  if (!(typeof window.google === 'undefined')) {
    var colors = {
      "network": {
        "color": "#00693B",
        "markers": {
          "colorAxis": ["#00693B", "#00693B"],
          "legend": "none"
        },
        "regions": {
          "colorAxis": ["#eeeeee", "#00693B"]
        }
      },
      "selection": {
        "color": "#2e4969",
        "markers": {
          "colorAxis": ["#2e4969", "#2e4969"],
          "legend": "none"
        },
        "regions": {
          "colorAxis": ["#eeeeee", "#2e4969"]
        }
      },
      "overlay": {
        "colorAxis": ["#ff6600", "#ff6600"]
      }
    }

  var dataUrl = window.alex.GDW_V1_URL || "/api/gdw-v1-de.json";

    function rendermaps() {
      if (typeof window !== 'object') {
        return;
      }
      var httpRequest = new XMLHttpRequest()
      httpRequest.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          var data = JSON.parse(this.responseText);
          var enableRegionInteractivity = true;

          var gdw_tables = document.getElementsByClassName('chart__table');
          [].forEach.call(gdw_tables, function (v, i, a) {
            rendertable(v, i, a);
            if (i == gdw_tables.length - 1) {
              // provide event when tables are rendered eg. to calculate sliders height
              var event = new Event('renderDone');
              window.dispatchEvent(event);
            }
          });

          // https://github.com/w3c/IntersectionObserver/tree/master/polyfill
          if ('IntersectionObserver' in window === false) {
            var gdw_maps = document.getElementsByClassName('chart__map');
            [].forEach.call(gdw_maps, function (v, i, a) {
              rendermap(v, i, a);
            });
          } else {
            // render maps asynchronus
            // https://usefulangle.com/post/118/javascript-intersection-observer
            let observer = new IntersectionObserver(
              (entries, observer) => {
                entries.forEach(entry => {
                  if (entry.isIntersecting) {
                    if (typeof entry.target.attributes['data-gchart'] == 'undefined') return;
                    var nid = entry.target.attributes['data-gchart'].nodeValue;
                    var gdw_maps = document.querySelectorAll('[data-gchart="' + nid + '"]');
                    [].forEach.call(gdw_maps, function (v, i, a) {
                      rendermap(v, i, a);
                      observer.unobserve(entry.target);
                    });
                  }
                });

              }, {
              threshold: [0, 0.5, 1]
            });

            var gdw_observe = document.getElementsByClassName('chart__map');
            [].forEach.call(gdw_observe, function (v, i, a) {
              observer.observe(v);
            });
          }

          function rendertable(el) {
            var key = el.dataset.gtable;
            if (typeof data[key] !== 'object') {
              console.log('error: "' + key + '" not available in json')
              return;
            }
            var l = data[key].data[0].length;
            var context = l - 2;
            var amount = l - 1;
            var foot = '<table><thead><tr><th scope="row">' + data[key].data[0][context] + '</th><th scope="row">' + data[key].data[0][amount] + '</th></tr></thead><tbody>';
            var rankingMax = 10;
            if (rankingMax > data[key].data.length) {
              rankingMax = data[key].data.length - 1;
            }
            for (var i = 0; i < rankingMax; i++) {
              foot += '<tr><td>' + data[key].data[i + 1][context] + '</td><td>' + data[key].data[i + 1][amount] + '</td></tr>';
            }
            foot += '</tbody></table>';
            el.innerHTML = foot;
          }

          function rendermap(el) {

            var key = el.dataset.gchart;
            if (typeof data[key] !== 'object') {
              console.log('error: "' + key + '" not available in json')
              return;
            }

            if ((' ' + el.className + ' ').indexOf(' render-done ') > -1) { return false; }

            if (data[key].options.displayMode == "markers") {
              enableRegionInteractivity = true;
            } else {
              //enableRegionInteractivity = false;
            }

            // max size of circles depending on chart width (16 or 32)
            var wmin = 4;
            var wmax = 16;
            var w = el.offsetWidth;
            if (w > 750) {
              wmax = 32;
            }

            // overlay marker color: check if more than one map is in the container
            if (el.previousSibling.previousSibling !== null) {
              var colorAxis = colors.overlay.colorAxis;
            } else {
              var colorAxis = colors[data[key].info.source][data[key].options.displayMode].colorAxis;
            }

            var chartoptions = {
              "region": data[key].options.region,
              "resolution": data[key].options.resolution,
              "displayMode": data[key].options.displayMode,
              "markerOpacity": 1,
              "datalessRegionColor": "#ffffff",
              "sizeAxis": {
                "minValue": data[key].options.minValue,
                "maxValue": data[key].options.maxValue,
                "minSize": wmin,
                "maxSize": wmax
              },
              "colorAxis": {
                "colors": colorAxis
              },
              "magnifyingGlass": {
                "enable": true,
                "zoomFactor": 5
              },
              "backgroundColor": {
                "fill": "transparent",
                "stroke": "transparent",
                "strokeWidth": 0
              },
              "enableRegionInteractivity": enableRegionInteractivity,
              "interactivity": false,
              "tooltip": {
                "trigger": "focus", // focus | selection
                "textStyle": { "fontSize": 10 }
              },
              "legend": colors[data[key].info.source][data[key].options.displayMode].legend,
              "width": "100%",
              "keepAspectRatio": true,
            };

            var chart = new google.visualization.GeoChart(el);

            chart.draw(google.visualization.arrayToDataTable(data[key].data), chartoptions);
            el.classList.add("render-done");

          }
        }
      }

      httpRequest.open('GET', dataUrl)
      httpRequest.send()
    }

    google.charts.load('current', {
      'packages': ['geochart'],
      'mapsApiKey': 'AIzaSyBxVYGAKE5PtvEKbdZGNApbXUgoOO8NVUE'
    });

    google.charts.setOnLoadCallback(rendermaps);
  }
}



export default class Chart {
  constructor($el, config) {
    this.config = {
      wrapperClass: 'chart__media',
      ...config,
    };
    this.$el = $el;
    this.$media = $el.querySelector('.chart__template');
    this.isDesktop = true; // mql.matches;

    this.Overlay = new Overlay({
      isDesktop: this.isDesktop,
      onGlobalConsent: this.onGlobalConsent,
      onConsent: this.onConsent,
      consentCookieName: 'avh-cookie-maps',
      $el: this.$el,
    });

    // Check if cookie exists and is set to 'accept'
    if (document.cookie.split(';').filter(function(item) {
      return item.indexOf('avh-cookie-maps=accept') >= 0;
    }).length) {
      this.onConsent;
      this.Overlay.consent();
    } else {
      this.Overlay.init();
    }
  }

  onConsent = () => {
    // An additional script has to be inserted and loaded into the DOM
    const helperScriptUrl = 'https://www.gstatic.com/charts/loader.js';
    const scripts = document.getElementsByTagName('script');

    // Check if the script exists in DOM
    let existsInDom = false;
    for (let i = scripts.length; i--;) {
      if (scripts[i].src == helperScriptUrl) existsInDom = true;
    }

    // This script will only be inserted if it doesnt exist yet (duplicate prevention)
    if ( !existsInDom && helperScriptUrl ) {
      const script = document.createElement( 'script' );
      script.src = helperScriptUrl;
      document.getElementsByTagName( 'head' )[0].appendChild( script );

      // Finally we can set further js for onLoad when the script is inserted and loaded into the DOM
      script.onload = () => {
          this.$el.appendChild(this.$media.content),
          initMap(),
          this.Overlay.initOverlay(false);
      }
    } else {
      this.$el.appendChild(this.$media.content),
      initMap(),
      this.Overlay.initOverlay(false);
    }
  }

  onGlobalConsent = () => {
    mql.addListener(onMediaQueryChange);
    onMediaQueryChange();
  }

  deconstructor = () => {
    this.Overlay.destroy();
  }
}

const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.m})`);

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];
  document
    .querySelectorAll(`.${componentWrapperClass}`)
    .forEach(($chart) => {
      componentStore.push(new Chart($chart, {}));
    });
}

mql.addListener(onMediaQueryChange);
onMediaQueryChange();

/* END - Cookie Layer */
