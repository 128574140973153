class Banner {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.$closeBanner = this.$el.querySelector('.banner__close-button');
    this.$getDateTime = this.$el.getAttribute('data-date-time');
    this.$unixDateTime = Date.parse(this.$getDateTime);

    this.getCookie();

    this.$el.classList.add('banner--initialized');

    // Add events
    if (this.$closeBanner) {
      this.$closeBanner.addEventListener('click', () => {
        this.Setcookie('avh_banner_closed');
        this.$el.classList.add('banner--hidden');
      });
    }
  }

  getCookie() {
    const bannerCookie = 'avh_banner_closed';
    let bannerName = '';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf('avh_banner_closed') === 0) {
        bannerName = c.substring(bannerCookie.length, c.length);
      }
    }
    if (this.$closeBanner) {
      if (bannerName === '') {
        this.$el.classList.remove('banner--hidden');
        const result1 = bannerName;
        const result2 = result1.substring(1);
        if (this.$unixDateTime < result2) {
          this.$el.classList.add('banner--hidden');
        }
      } else {
        const result1 = bannerName;
        const result2 = result1.substring(1);
        if (this.$unixDateTime > result2) {
          document.cookie = 'avh_banner_closed= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
          this.$el.classList.remove('banner--hidden');
        } else {
          this.$el.classList.add('banner--hidden');
        }
      }
    }
  }

  Setcookie(cname) {
    const days = 30;
    const myDate = new Date();
    const cookieValue = this.$unixDateTime;
    myDate.setTime(myDate.getTime() + (days * 24 * 60 * 60 * 1000));

    if (this.$closeBanner) {
      document.cookie = cname + '='.concat(cookieValue).concat(';expires=').concat(myDate.toGMTString());
    }
  }
}

document.querySelectorAll('.banner').forEach($el => new Banner($el));
