import { BREAKPOINTS } from 'javascripts/constants';

document.querySelectorAll('.article-aside--sticky').forEach(($el) => {
  const $header = document.querySelector('.header');
  const $aside = $el;

  const observeHeaderState = (mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.attributeName === 'class') {
        const panelOpen = $header.classList.contains('header--panel-open');
        const unpinned = $header.classList.contains('header__headroom--unpinned');
        const pinned = $header.classList.contains('header__headroom--pinned');

        if (unpinned) {
          const headerHeight = 35;
          $aside.style.top = `${headerHeight}px`;
        }

        if (!panelOpen && pinned) {
          const headerHeight = $header.offsetHeight + 20;
          $aside.style.top = `${headerHeight}px`;
        }
      }
    });
  };

  // Observe header state to set dynamic top position
  const mutationObserver = new MutationObserver(observeHeaderState);
  const mqXL = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

  const onMediaQueryChange = () => {
    if (mqXL.matches) {
      mutationObserver.observe($header, { attributes: true });
    } else {
      mutationObserver.disconnect();
    }
  };
  mqXL.addListener(onMediaQueryChange);
  onMediaQueryChange();
});

document.querySelectorAll('.js-aside-toggle').forEach(($button) => {
  const $aside = $button.closest('.article-aside');
  const attributes = {
    'aria-controls': $button.getAttribute('data-controls'),
    'aria-expanded': 'false',
    role: 'button',
    tabindex: '0',
  };

  const toggle = (event) => {
    if (event.keyCode && event.keyCode !== 13) {
      return;
    }

    event.stopPropagation();
    const $content = document.getElementById($button.getAttribute('aria-controls'));

    if ($content) {
      const contentVisible = $button.getAttribute('aria-expanded') === 'true';
      $button.setAttribute('aria-expanded', (!contentVisible).toString());

      if (contentVisible) {
        $content.classList.remove('article-aside__content--visible');
      } else {
        $content.classList.add('article-aside__content--visible');
      }
    }
  };

  const collapse = (boolean) => {
    if ($aside) {
      $aside.classList[boolean ? 'add' : 'remove']('article-aside--collapsible');
      ['click', 'keypress'].forEach(
        type => $button[`${boolean ? 'add' : 'remove'}EventListener`](type, toggle),
      );

      Object.keys(attributes).forEach((key) => {
        $button[`${boolean ? 'set' : 'remove'}Attribute`](key, boolean ? attributes[key] : null);
      });
    }
  };

  const mediaQueryChange = (mq) => {
    collapse(!mq.matches);
  };

  const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);
  mql.addListener(mediaQueryChange);
  mediaQueryChange(mql);
});
