import ClipboardJS from 'clipboard';
import Tooltip from '../../atoms/tooltip/tooltip';

document.querySelectorAll('.share').forEach(($share) => {
  const $trigger = $share.querySelector('.share__trigger');
  const $tooltip = $share.querySelector('.share__tooltip');
  const $link = $share.querySelector('.share__link');

  return new Tooltip($trigger, $tooltip, {
    closeButtonClass: 'button.share__close',
    openOnHover: false,
    closeOnFocusOut: false,
    placement: 'top',
    orientations: ['top', 'bottom'],
    onClick: () => {
      $link.focus();
    },
  });
});

document.querySelectorAll('.js-copy').forEach(($copy) => {
  const text = $copy.getAttribute('data-clipboard-text');
  if (ClipboardJS.isSupported()) {
    const clipboard = new ClipboardJS($copy, {
      container: $copy.closest('.share__tooltip'),
      text: () => text,
    });

    clipboard.on('success', () => {
      const $label = $copy.querySelector('.share__label');
      $label.innerHTML = $copy.getAttribute('data-copied-message');

      setTimeout(() => {
        $label.innerHTML = $copy.getAttribute('data-copy-message');
      }, 3000);
    });

    clipboard.on('error', () => {
      clipboard.destroy();
    });
  } else {
    $copy.setAttribute('style', 'display: none;');
  }
});
