import { BREAKPOINTS } from 'javascripts/constants';
import Masonry from 'masonry-layout';

const Gallery = ($gallery) => {
  // Get references
  const $items = $gallery.querySelector('.gallery__items');

  // Masonry grid
  const masonry = () => {
    const $item = '.gallery__item';
    const mqm = window.matchMedia(`(min-width: ${BREAKPOINTS.m})`);

    if (mqm) {
      const masonryGallery = new Masonry($items, {
        itemSelector: $item,
        columnWidth: $item,
      });

      $items.addEventListener('lazyloaded', () => {
        masonryGallery.layout();
      });
    }
  };

  if ($items) {
    // Init Masonry
    masonry();

    // Init Masonry again on resize
    window.addEventListener('resize', masonry);
  }
};

document.querySelectorAll('.js-gallery').forEach($gallery => Gallery($gallery));
