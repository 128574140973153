import 'van11y-accessible-tab-panel-aria/dist/van11y-accessible-tab-panel-aria.min';

document.querySelectorAll('.newsroom').forEach(($dropdown) => {
  const $toggle = $dropdown.querySelector('.newsroom__button');
  const $toggleText = $toggle.querySelector('.newsroom__button-text');
  const $content = $dropdown.querySelector('.newsroom__tablist');

  const toggleDropdown = (event) => {
    const $target = event.currentTarget;
    event.stopPropagation();

    if ($target) {
      const dropdownContentVisible = $toggle.getAttribute('aria-expanded') === 'true';

      // Switch aria state
      $toggle.setAttribute('aria-expanded', (!dropdownContentVisible).toString());

      // Switch content visiblity
      if (dropdownContentVisible) {
        $content.classList.remove('newsroom__tablist--visible');
      } else {
        $content.classList.add('newsroom__tablist--visible');
      }
    }
  };

  if ($toggle) {
    $toggle.addEventListener('click', toggleDropdown);
  }

  // Change toggle text
  $dropdown.querySelectorAll('.newsroom__tab-link').forEach(($link) => {
    $link.addEventListener('click', () => {
      $toggleText.textContent = $link.text;
    });
  });
});
