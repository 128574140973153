/* global twttr */

import Overlay from 'javascripts/globals/overlay';

class TwitterTimeline {
  constructor($el) {
    this.$el = $el;

    this.Overlay = new Overlay({
      onConsent: this.onConsent,
      consentCookieName: 'avh-cookie-twitter',
      $el: this.$el,
    });


    if (document.cookie.split(';').filter(
      item => item.indexOf('avh-cookie-twitter=accept') >= 0,
    ).length) {
      this.Overlay.consent();
    } else {
      this.Overlay.init();
    }
  }

  onConsent = () => {
    this.injectTwitterScript(this.initTimeline);

    const $overlays = document.querySelectorAll('.twitter-timeline__overlay');
    $overlays.forEach(($overlay) => {
      // remove overlay
      if ($overlay && $overlay.parentNode) {
        $overlay.parentNode.removeChild($overlay, $overlay.parentNode);
      }
    });
  }

  injectTwitterScript = (callback) => {
    const scriptId = 'twitter-timeline-script';
    const existingScript = document.getElementById(scriptId);

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      script.id = scriptId;
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  initTimeline = () => {
    if (typeof twttr !== 'undefined') {
      twttr.widgets.load(this.$el.querySelector('.twitter-timeline'));
    }
  }
}

document.querySelectorAll('.twitter-timeline__wrap').forEach($timeline => new TwitterTimeline($timeline));
