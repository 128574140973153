/**
 * @typedef {object} GenderSymbolClasses
 * @property {string} element
 * @property {string} initializedModifier
 * @property {string} symbolElement
 */


export default class GenderSymbol {
  /** @type {GenderSymbolClasses} */
  static DefaultClasses = {
    element: 'js-gender-symbols',
    initializedModifier: 'gender-symbols--initialized',
    symbolElement: 'gender-symbols__symbol',
  }

  /** @type {HTMLElement} */
  $element;

  /** @type {GenderSymbolClasses} */
  classes;

  constructor($element, classes = {}) {
    this.$element = $element;
    this.classes = {
      ...GenderSymbol.DefaultClasses,
      ...classes,
    };

    this.init()
      .then(() => {
        this.$element.classList.add(this.classes.initializedModifier);
      });
  }

  async init() {
    this.$element
      .querySelectorAll(`.${this.classes.symbolElement}`)
      .forEach(($symbol) => {
        document.addEventListener('scroll', this.onScroll.bind($symbol));
        document.addEventListener('resize', this.onScroll.bind($symbol));
        this.onScroll.call($symbol);
      });
  }

  onScroll() {
    const { innerHeight } = window;
    const { top, bottom } = this.getBoundingClientRect();
    const isVisible = top >= 0 && bottom <= innerHeight;
    const percent = parseFloat(this.dataset.percent || '0');

    if (isVisible) {
      this.style.setProperty('--percent', percent);
    } else {
      this.style.setProperty('--percent', 0);
    }
  }

  static InitializeDefaults() {
    const { element, initializedModifier } = GenderSymbol.DefaultClasses;
    document
      .querySelectorAll(`.${element}:not(.${initializedModifier})`)
      .forEach($genderSymbol => new GenderSymbol($genderSymbol));
  }
}

GenderSymbol.InitializeDefaults();
